import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Image, Modal } from "react-bootstrap";
import Loading from "../loading";
import { putApi, postApi, delApi } from "../../utils/requestUtils";
import { USER_API, GAME_API } from "../api-url";
import { showError, showSuccess } from "../toast";
import avt from "../../assets/images/slider/userAvatar.svg";

const AvatarModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [avatarInfo, setAvatarInfo] = useState({});
  const [avatarList, setAvatarList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(null);
  const fetchAvatar = async (nextPage) => {
    setIsLoading(true);
    postApi(GAME_API.NFT_ONCHAIN_SEARCH, {
      filter: [
        {
          id: "userPath",
          value: localStorage.getItem("userPath"),
          op: "eq",
        },
      ],
      limit: 12,
      page: nextPage,
    })
      .then((res) => {
        setAvatarList([...avatarList, ...res.data.rows]);
        setCount(res?.data?.count);
        setPage(res?.data?.nextPage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAvatar(null);
  }, []);
  const handleClose = () => props.onHide();

  useEffect(() => {
    setAvatarInfo({ ...avatarInfo, image: props.image });
  }, [props.image]);
  const seeMoreAction = () => {
      fetchAvatar(page)
  };

  return (
    <Modal size="md" show={props.show} onHide={props.onHide}>
      {isLoading && <Loading />}
      <Modal.Header closeButton></Modal.Header>
      <div className="modal-body space-y-20 pd-40">
        <h3 className="text-left">Avatar</h3>
        <p className="text-left mt-2 mb-3">Choose your avatar</p>
        <hr className="mb-3" />
        <div className="row">
          <div className="col-6">
            <div className="avatar rounded-full bg-transparent">
              <Image
                src={avatarInfo?.image || avt}
                alt=""
                responsive
                className="w-100"
              />
            </div>
          </div>
          <div className="col-6 d-flex algin-items-center justify-content-center">
            {avatarInfo?.image && (
              <button
                className="sc-button sc-button-delete bag fl-button pri-3 no-bg m-auto"
                onClick={() => {
                  setAvatarInfo(null);
                }}
              >
                <i className="fa fa-trash mr-2"></i>
                Remove Avatar
              </button>
            )}
          </div>
          <div className="col-12 mt-4">
            <h5> NFT Avatar </h5>
          </div>
          <div className="avatar-list-container">
            {avatarList.map((av) => (
              <div className="col-2 mt-4">
                <div className="avatar-small rounded-full ">
                  <Image
                    src={av?.image}
                    alt=""
                    responsive
                    className="w-100"
                    onClick={() => setAvatarInfo(av)}
                  />
                </div>
              </div>
            ))}
            <div className="col-12 text-center mt-4">
              {count > avatarList.length && ( 
                <button
                  className="sc-button-see-more bag fl-button pri-3 no-bg m-auto"
                  onClick={() => seeMoreAction()}
                >
                  See More
                </button>
              )}
            </div>
          </div>
        </div>

        <hr className="mb-2 mt-6" />

        <button
          disabled={props?.image === avatarInfo?.image}
          className="btn btn-primary"
          onClick={() => {
            const apiChange = !avatarInfo
              ? delApi(`${USER_API.PROFILE_NFT}`)
              : putApi(`${USER_API.PROFILE_NFT}`, {
                  address: avatarInfo?.contractAddress,
                  tokenId: avatarInfo?.tokenId,
                });

            apiChange
              .then(() => {
                showSuccess("Profile image was reset");
                props.onSuccess();
                props.onHide();
              })
              .catch((err) => {
                showError(err?.response?.data?.message);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        >
          Save avatar
        </button>
      </div>
    </Modal>
  );
};

export default AvatarModal;
