import React, { useState, useEffect, useRef } from 'react';


import { Image, Modal, Pagination } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// ** Import utils
import { getApi, putApi, postApi } from '../../utils/requestUtils';
import { showError, showSuccess } from '../../components/toast';
import Loading from '../../components/loading';
import { set, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import MetaMask from '../../assets/images/icon/MetaMask.svg';
import { USER_API, API_FILE, WEB3_API } from '../../components/api-url';
import { Copy, Trash } from 'react-bootstrap-icons';
import { ethers, BigNumber } from 'ethers';
import Web3 from 'web3';
import ContractABI from '../../assets/abi/AirDropBySignature.json';
// import AirdropData from '../../assets/data/AirdropData.json';

// ** Import image
import IMGCLAIM from '../../assets/images/box-item/imageClaim.png';
import IMGSTARTCLAIM from '../../assets/images/box-item/imagestartclaim.png';
import NOAIRDROP from '../../assets/images/box-item/noairdrop.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const valueFilterInit = {
  page: 1,
  limit: 50,
}

const OtherWallet = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [reload, setReaload] = useState(0)
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [searchAccount, setSearchAccount] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [tokenIds, setTokenIds] = useState('');
  const [contract, setContract] = useState(null);
  const [nftClaimed, setNftClaimed] = useState([]);
  const [isClaimed, setIsClaimed] = useState(true);
  const [gasEstimate, setGasEstimate] = useState(0);
  const [gasPrice, setGasPrice] = useState(0);
  const [gasFee, setGasFee] = useState(0);
  const [AirdropData, setAirdropData] = useState(null);
  const [nftCount, setNftCount] = useState(0);
  const [valueFilter, setValueFilter] = useState(valueFilterInit);


  useEffect(() => {
    if (selectedAccount) {
      setIsLoading(true)
      postApi(WEB3_API.GET_NFT_CLAIMED, {
        "filter": [
          {
            "id": "ownerAddress",
            "value": searchAccount.toLowerCase(),
            "op": "eq"
          }
        ],
        "orderBy": "tokenId",
        "reverse": false,
        "limit": valueFilter?.limit,
        "page": valueFilter?.page,
      }).then((res) => {
        setIsLoading(false)
        setNftClaimed(res?.data?.rows || [])
        setNftCount(res?.data?.count || 0)
        if (res?.data?.rows.length > 0) {
          let claimed = true;
          res?.data?.rows.map(element => {
            if (!element.claimTnx) {
              claimed = false;
            }
          })
          setIsClaimed(claimed)
        }
      }).catch((error) => {
        console.error('Error fetching nft claimed', error);
      });
    }
  }, [reload, selectedAccount, valueFilter])
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setSelectedAccount(searchAccount);
    }
  };
  return (
    <>
      <div style={{padding: '30px 20px' }}>
        {isLoading && <Loading />}
        
      </div>
        <>
          <div className='d-flex' style={{ marginTop: '20px', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4 className='text-white mt-4'>Claim history</h4>
            
          </div>
          <div className='w-100 mt-4 widget widget-search mgbt-24'>
            
            <InputGroup>
              <Form.Control
                placeholder="Search..."
                value={searchAccount}
                onChange={(e) => setSearchAccount(e.target.value)}
                style={{ height: '46px', maxWidth: 'calc(100% - 120px)'}}
                className='bg-primary-custom border-1'
                onKeyDown={handleKeyDown}
              />
              {/* <Button variant="outline" className='no-bg' >
                <Copy color="#fff" />
              </Button> */}
              <Button 
                onClick={() => setSelectedAccount(searchAccount)}
                variant="outline-secondary"
              >
                <i className="icon-fl-search-filled mr-4"></i>
                Check</Button>
            </InputGroup>
          </div>
          {selectedAccount && !nftClaimed && (
            <div className="bg-primary-custom b-r-12" style={{ marginTop: '30px', padding: '30px 20px' }}>
              <div className='text-center'>
                <p>
                  <img src={NOAIRDROP} alt="claim" className='mt-4' />
                </p>
                <h6 className='text-white mt-4 font-weight-normal'>{selectedAccount} has no airdrops at the moment.</h6>
                
              </div>
            </div>
          )}
          {selectedAccount && nftClaimed && (
            <div className="bg-primary-custom b-r-12" style={{ marginTop: '30px', padding: '30px 20px' }}>
              <div className='text-center'>
                <p>
                  <img src={IMGSTARTCLAIM} alt="claim" className='mt-4' />
                </p>
                <h6 className='text-white mt-4 font-weight-normal'>{selectedAccount} has total {nftCount} airdropped NFTs</h6>
                
              </div>
            </div>
          )}
          {nftClaimed.length > 0 && (
            <div style={{marginTop: 30}}>
              <h4 className='text-white mt-4'>Airdrop</h4>
              <div style={{ marginTop: '20px' }}>
                <Tabs >
                  <TabPanel style={{ padding: '10px 0px' }}>
                    <div className='w-100'>
                      {
                        nftClaimed.map((item, index) => (
                          <div key={index} className="card-airdrop bg-primary-custom b-r-12 float-left" style={{ margin: '10px' }}>
                            <div className="sc-card-product menu_card style-h7" style={{background: 'none', margin: '0'}}>
                              <div className="card-title">
                                <h5 className='text-center'>
                                  {item?.nftData?.name}

                                </h5>
                              </div>
                              <div className="card-media" style={{ minWidth: '100%', minHeight: '154px', backgroundColor: '#353541' }}>
                                <img src={item?.nftData?.externalUrl} alt={item?.nftData?.externalUrl} />

                              </div>

                              <div className="meta-info">
                                
                                <div style={{ fontSize: '13px' }}><strong>Claimed: </strong> <strong className={item.claimTnx ? 'color-gradient' : 'text-danger'}> {item?.claimTnx ? 'Yes' : 'No'}</strong> </div>
                              </div>
                              <div className="w-100 mt-3" style={{ fontSize: '13px' }}>
                                <span style={{color: '#7A798A'}}>Claimed time: </span> 
                                {item.claimTnx ? new Date(item?.updatedAt).toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'long' }) : '-'}
                              </div>
                            </div>
                          </div>
                        ))
                      }

                    </div>
                  </TabPanel>
                </Tabs >
              </div>
            </div>
          )}
          <div className='w-100'> 
            {nftCount > 0 && (
                <div className='pagination m-auto text-center'>
                  <Pagination className="px-4">
                    <Pagination.Prev 
                      onClick={() => {
                        setValueFilter({
                          ...valueFilter,
                          page: valueFilter?.page - 1
                        })
                      }}
                    />
                      {Array.from({ length: Math.ceil(nftCount / valueFilter.limit) }, (_, index) => (
                        <Pagination.Item 
                          key={index}
                          active={index + 1 === valueFilter?.page}
                          onClick={() => {
                            setValueFilter({
                              ...valueFilter,
                              page: index + 1
                            })
                          }}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                      
                    <Pagination.Next 
                      onClick={() => {
                        setValueFilter({
                          ...valueFilter,
                          page: valueFilter?.page + 1
                        })
                      }}
                    />
                  </Pagination>
                </div>
              
              )}
            </div>
        </>
    </>
  )
}

export default OtherWallet;