import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import { USER_API } from '../components/api-url';


import { postApi } from '../utils/requestUtils';

import { showError, showSuccess } from '../components/toast';


import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import Loading from '../components/loading';

import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object().shape({
  email: yup.string().email('Email’s format must be valid').required('Email is required'),
})
const schemaVerify = yup.object().shape({
  verifyCode: yup.string().min(6, 'OTP must be 6 characters').required('Verification code is required'),
});
const schemaPassword = yup.object().shape({
  password: yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Passwords must match').required('Confirm Password is required')
});

const Login = () => {

  const defaultValues = {
    email: window.localStorage.getItem('rememberEmail') ? window.localStorage.getItem('rememberEmail') : ''
  }
  const [step, setStep] = useState(1)
  const [email, setEmail] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [loading, setLoading] = useState(false)

  const history = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const { register: registerVerify, setValue: setValueVerify, handleSubmit: handleSubmitVerify, formState: { errors: errorsVerify } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schemaVerify)
  });
  const { register: registerPassword, handleSubmit: handleSubmitPassword, formState: { errors: errorsPassword } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schemaPassword)
  });

  const handleEmailSubmit = async (data) => {
    try {
      const res = await postApi(`${USER_API.PASSWORD_RECOVERY}`, {
        email: data.email
      })
      if (res?.data) {
        setEmail(data.email)
        setStep(2)
      }
    } catch (error) {
      showError(error?.response?.data?.message)
    }
  };

  const handleVerificationCodeSubmit = async (data) => {
    setLoading(true)
    try {
      const res = await postApi(`${USER_API.PASSWORD_RECOVERY_VERIFY}`, {
        email: email,
        otp: data?.verifyCode
      })
      if (res?.data) {
        setEmail('')
        window.localStorage.setItem('accessToken', res?.data?.token)
        setStep(3)
      }
    } catch (error) {
      showError(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
    
  }

  

  const handleResetPasswordSubmit = async (data) => {
    setLoading(true)
    try {
      const res = await postApi(`${USER_API.PASSWORD_RECOVERY_RESET}`, {
        newPassword: data.password,
        newPasswordConfirm: data.confirmPassword
      })
      if (res?.data) { 
        window.localStorage.removeItem('accessToken')
        showSuccess('Reset password successfully')
        history('/login')
      }
    } catch (error) {
      showError(error?.response?.data?.message)
    } finally {
      setLoading(false);
    }
  }

  const handleVerificationCodeChange = (e, index) => {
    const value = e.target.value
    const form = e.target.form
    
    const newVerificationCode = [...verificationCode]
    newVerificationCode[index] = value
    if(index < 6 && value.length){ 
      for(let i = index + 1; i < 6; i++) {
        newVerificationCode[i] = ''
      }
      form.elements[index + value.length].focus()
    }
    
    setVerificationCode(newVerificationCode.join(''))
    setValueVerify('verifyCode', newVerificationCode.join(''))
    if(newVerificationCode[5]){
      form.elements[7].focus()
    }
  }
  
  return (
    <div>
      <Header />
      {loading && <Loading />}
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Forgot Password</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-login tf-section">
        <div className="themesflat-container">
          <div className="row">
            {step === 1 && (
              <div className="col-12">

                <h2 className="tf-title-heading ct style-1">
                  Forgot Password? 🔒

                </h2>

                <div className="flat-form box-login-email">
                  <h6 className='text-center mb-4'>Enter your email and we′ll send you instructions to reset your password</h6>


                  <div className="form-inner mt-4">
                    <form noValidate onSubmit={handleSubmit(handleEmailSubmit)} id="contactform">
                      <input className={`form-control ${errors.email ? 'is-invalid' : ''}`} {...register('email')} id="email" tabIndex="1" aria-required="true" type="email" placeholder="Your Email Address" required />
                      {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                      <button className="submit" type="submit">Send reset link</button>
                    </form>
                    <p className='text-center mt-4'>
                      <Link style={{ marginLeft: '5px', fontWeight: 'bold' }} to="/login">Back to sign in</Link>
                    </p>
                  </div>

                </div>

              </div>

            )}
            {step === 2 && (
              <div className="col-12">

                <h2 className="tf-title-heading ct style-1">
                  Verify the 6 digit Numbers 🔒

                </h2>

                <div className="flat-form box-login-email">
                  <h6 className='text-center mb-4'> Please check your email you entered and verify the 6 digit number to reset your password </h6>


                  <div className="form-inner mt-4">
                    <form noValidate autoComplete='off' onSubmit={handleSubmitVerify(handleVerificationCodeSubmit)}>
                    {[0, 1, 2, 3, 4, 5].map((index) => (
                        <input
                          key={index}
                          id={`verification-code-${index}`}
                          type='tel'
                          value={verificationCode[index] || ''}
                          onChange={(e) => handleVerificationCodeChange(e, index)}
                          className="form-control text-center"
                          maxLength={1}
                          autoFocus={index === 0}
                          style={{ width: '10%', float: 'left', marginLeft: '5%', padding: '10px 2%', textAlign: 'center'}}
                        />
                      ))}
                      <input hidden className={`form-control ${errors.verifyCode ? 'is-invalid' : ''}`} {...registerVerify('verifyCode')} tabIndex="1" aria-required="true" />
                      {!!errorsVerify.verifyCode && (
                        <div className="invalid-feedback">{errorsVerify.verifyCode.message}</div>
                      )}
                      <button className="submit" type="submit">Verify</button>
                    </form>
                    <p className='text-center mt-4'>
                      <Link style={{ marginLeft: '5px', fontWeight: 'bold' }} to="/login">Back to sign in</Link>
                    </p>
                  </div>

                </div>

              </div>
            )}
            {step === 3 && (
              <div className="col-12">

                <h2 className="tf-title-heading ct style-1">
                  Reset password 🔒

                </h2>

                <div className="flat-form box-login-email">
                  
                  <div className="form-inner mt-4">
                    <form noValidate autoComplete='off' onSubmit={handleSubmitPassword(handleResetPasswordSubmit)}>
                    <input className={`form-control ${errorsPassword.password ? 'is-invalid' : ''}`} {...registerPassword('password')} id="password" tabIndex="2"  aria-required="true" type="password" placeholder="Your Password" required />
                    {errorsPassword.password && <div className="invalid-feedback">{errorsPassword.password.message}</div>}
                    <input className={`form-control ${errorsPassword.confirmPassword ? 'is-invalid' : ''}`} {...registerPassword('confirmPassword')} id="confirmPassword" tabIndex="2"  aria-required="true" type="password" placeholder="Confirm Password" required />
                    {errorsPassword.confirmPassword && <div className="invalid-feedback">{errorsPassword.confirmPassword.message}</div>}
                    
                      <button className="submit" type="submit">Reset Password</button>
                    </form>
                    <p className='text-center mt-4'>
                      <Link style={{ marginLeft: '5px', fontWeight: 'bold' }} to="/login">Back to sign in</Link>
                    </p>
                  </div>

                </div>

              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Login;
