
import { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import NftContent from './NftContent';
import SideBar from '../components/layouts/home-8/SideBar';
import { getApi, postApi } from '../utils/requestUtils';
import { GAME_API } from '../components/api-url';
import Footer from '../components/footer/Footer';
import TrendingNft from '../components/layouts/home/TrendingNft';
import Collections from '../components/layouts/nfts/Collections';
import Loading from '../components/loading';

const initNftValueFilter = { 
  "type": [],
  "dapp": [],
  "filter": [],
  "orderBy": "createdAt",
  "keyword": "",
  "keywordBy": ["name"],
  "reverse": false,
  "page": 1,
  "limit": 8
}
const initCollectionValueFilter = { 
  "type": [],
  "dapp": [],
  "filter": [],
  "orderBy": "createdAt",
  "keyword": "",
  "keywordBy": ["name"],
  "reverse": false,
  "page": 1,
  "limit": 6
}

const Marketplace = () => {
  

  const [liveAuctionData, setLiveAuctionData] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [nftFilter, setNftFilter] = useState(initNftValueFilter)
  const [collectionFilter, setCollectionFilter] = useState(initCollectionValueFilter)

  const fetchAutictonData = async () => {
      try {
        setIsLoading(true);
        const res = await postApi(GAME_API.NFT_ITEM_SEARCH, nftFilter)
        setIsLoading(false);
        if(res.data){
          setLiveAuctionData(res.data);

        }
      } catch (error) { 
        console.log(error)
        setIsLoading(false);
      }
      

  }

  const fetchCollectionData = async () => { 
    try {
      setIsLoading(true);
      const res = await postApi(GAME_API.NFT_COLLECTION_SEARCH, collectionFilter)
      setIsLoading(false);
      if(res.data){
        setCollectionData(res.data);
      }
    } catch (error) { 
      console.log(error)
      setIsLoading(false);
    }
  }
  

  useEffect(() => { 
    if( nftFilter && collectionFilter){
      fetchAutictonData()
      fetchCollectionData()
    }
  }, [nftFilter, collectionFilter]);


  return (
    <div className='home-7'>
      {isLoading && <Loading />}
      <Header />
      <section className="flat-title-page inner bg4">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">NFT Marketplace</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TrendingNft 
        data={liveAuctionData}
        title="Newest Arrivals"
        valueFilter={nftFilter}
        setValueFilter={(value) => setNftFilter(value)}
        isShowMore={true}
      />
      <Collections 
        data={collectionData}
        title="Collections"
        valueFilter={collectionFilter}
        setValueFilter={(value) => setCollectionFilter(value)}
        isShowMore={true}
      />

      <Footer />

    </div>
  );
}

export default Marketplace;