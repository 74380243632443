import { defaultConfig } from '@web3modal/ethers/react';
export const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: process.env.REACT_APP_RPC,
};

export const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://app.daystarter.life', // origin must match your domain & subdomain
  icons: ['https://app.daystarter.life/assets/icon/Favicon.png']
};

export const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  defaultChainId: 1 // used for the Coinbase SDK
});

export const projectId = process.env.REACT_APP_PROJECT_ID_WALLETCONNECT;


