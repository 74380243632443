import React, { useState, useEffect } from 'react';
import Empty from '../../../components/empty';
import { getApi, putApi, postApi } from '../../../utils/requestUtils';
import { formatDateTimeShow, formatMoney } from '../../../utils/format';
import { FINANCE_API } from '../../../components/api-url';
import { Table } from 'react-bootstrap';
import Pagination from '../../../components/Pagination';
const valueFilterInit = {
  keyword: null,
  page: 1,
  limit: 10,
}
const Activites = () => {
  const myPath = localStorage.getItem('userPath')
  const [path, setPath] = useState('')
  const [reload, setReload] = useState(0)
  const [activity, setActivity] = useState([]);
  const [valueFilter, setValueFilter] = useState(valueFilterInit);
  const [total, setTotal] = useState(0)
  const fetchDataSource = async (pathParam) => {
    const filter = [
      {
        id: "userPath",
        value: pathParam + '%',
        op: "like",
      }
    ];
    const payload = {
      keyword: null,
      page: valueFilter.page,
      limit: valueFilter.limit,
      orderBy: 'date',
      reverse: true,
      filter: filter
    }
    try {
      const res = await postApi(`${FINANCE_API.SEARCH_FINANCE}`, payload)
      if (res?.data) {
        setTotal(res.data?.count);
        setActivity(res.data?.rows.map((item, index) => ({ ...item, index: index + 1 })) || [])
      }
    } catch (error) {
      console.log(error)
    }

  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const pathParam = params.get('path');
    if (pathParam) {
      setPath(pathParam);
    }

    fetchDataSource(pathParam)


  }, [reload])


  return (
    <div className='col-12 mt-4'>
      <div className='bg-primary-custom b-r-12 p-2 pb-4'>
        <div className='row pt-3 pl-3 pr-3'>
          <div className='col-12 pt-2 pb-2'>
            <h4 className='mt-2 text-uppercase font-weight-normal color-9'>Transaction DSP</h4>
          </div>
          <div className='m-auto w-100'>
            {(myPath !== path || activity.length === 0) && (
              <div style={{ width: '210px' }}>
                <Empty />
              </div>
            )}
            {(myPath === path && activity.length > 0) && (
              <div className="col-md-12">
                
                <Table striped bordered hover className="mt-4 custom-table">
                  <thead>
                    <tr className='color-9 uppercase'>
                      <th style={{width: '50px'}}>ID</th>
                      <th>Transaction type</th>
                      <th>Type</th>
                      <th>Amount</th>
                      <th>Issued date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activity.map((item, index) => (
                      <tr key={index}>
                        <td>#{item?.seqId}</td>
                        <td>{item?.amount > 0 ? 'Inflow' : 'Outflow'}</td>
                        <td>Point</td>
                        <td>{item?.amount > 0 ? '+' : '-'} {formatMoney(item?.amount)} DSP</td>
                        <td>{formatDateTimeShow(item?.date)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  total={total}
                  valueFilter={valueFilter}
                  setValueFilter={(value) => {
                    setValueFilter(value);
                    setReload(reload + 1);
                  }}
                />
              </div>
            )}
          </div>

        </div>
      </div>
    </div>
  )
}

export default Activites;