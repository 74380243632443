import React, { useState, useEffect } from 'react';
import Empty from '../../components/empty';

import Statistic from './Statistic';
import Activites from './Element/Activites';
const HomeTab = () => {
  
  return (
    <div className='row'>
      <Statistic />
      <div className='col-12 mt-4'>
        <div className='bg-primary-custom b-r-12 p-2 pb-4'>
          <div className='row pt-3 pl-3 pr-3'>
            <div className='col-12 pt-2 pb-2'>
              <h4 className='mt-2 text-uppercase font-weight-normal'>Inventory</h4>
            </div>
            <div className='m-auto' style={{width: '210px'}}>
              <Empty />

            </div>
            
          </div>
        </div>
      </div>
      <Activites />
      

      


    </div>
  )
}

export default HomeTab;