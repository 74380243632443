import React , { useState , Fragment } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Dropdown } from 'react-bootstrap';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Countdown from "react-countdown";
import CardModal from './CardModal'
import unlimit from '../../assets/images/box-item/unlimit.svg'

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import { useDispatch } from 'react-redux';
import { addToCart } from '../../actions/cartActions';

import { showError, showSuccess } from '../toast';

const LiveAuction = props => {
    const data = props.data;
    const title = props?.title;
    const dispatch = useDispatch();

    const handleAddToCart = (item) => {
      dispatch(addToCart(item));
    };

    const [modalShow, setModalShow] = useState(false);

    return (
        <Fragment>
            <section className="tf-section live-auctions">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-live-auctions">
                                <h2 className="tf-title pb-20">
                                    {title ? title : 'Trending Items'}
                                </h2>
                                <Link to="/explore-03" className="exp style2">EXPLORE MORE</Link>
                            </div>
                        </div>
                        <div className="col-md-12">
                          <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={20}

                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    767: {
                                    slidesPerView: 2,
                                    },
                                    991: {
                                    slidesPerView: 3,
                                    },
                                    1300: {
                                        slidesPerView: 5,
                                    },
                                }}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                            >
                                {
                                    data.slice(0,7).map((item,index) => (
                                        <SwiperSlide key={index}>
                                            <div className="swiper-container show-shadow carousel auctions">
                                                <div className="swiper-wrapper">
                                                    <div className="swiper-slide">
                                                        <div className="slider-item">										
                                                            <div className="sc-card-product menu_card style-h7">
                                                                <div className="meta-info style">
                                                                    <div className="author">
                                                                        <div className="avatar">
                                                                            <img src={item.gameImage} alt={item.name} />
                                                                        </div>
                                                                        <div className="info">
                                                                            <span>Dapp</span>
                                                                            <h6> <Link to="/authors-02">{item.gameName}</Link> </h6>
                                                                        </div>
                                                                    </div>
                                                                    <Link to="/login" className="wishlist-button heart">
                                                                      <span className="number-like"> 100 </span>
                                                                    </Link>
                                                                </div>
                                                                <div className="card-media">
                                                                    <Link reloadDocument to={`/item-details?vendor=${item?.vendor}&vendorGameCode=${item?.vendorGameCode}&code=${item?.code}`} >
                                                                      <img src={item.image} alt={item.name} />
                                                                    </Link>
                                                                    
                                                                    <div className="featured-countdown">
                                                                        <span className="slogan"></span>
                                                                        <span> 10000 <strong className='color-gradient'> DSP </strong> </span>
                                                                    </div>
                                                                    <div className="button-place-bid">
                                                                        <button onClick={() => {
                                                                          handleAddToCart(item);
                                                                          showSuccess('Item added to cart');
                                                                        }} className="sc-button style-place-bid style bag fl-button pri-3"><span>Add to card</span></button>
                                                                    </div>
                                                                </div>
                                                                <div className="card-title">
                                                                    <h5 className='text-center'><Link reloadDocument to={`/item-details?vendor=${item?.vendor}&vendorGameCode=${item?.vendorGameCode}&code=${item?.code}`}>"{item.name}"</Link></h5>
                                                                </div>
                                                                <div>
                                                                    <h6 className='text-center color-grey'> Quantity: <strong className='color-primary'> {item?.quantity ? item?.quantity : <img src={unlimit} />}</strong> </h6>
                                                                </div>
                                                            </div>      	
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Fragment>
        
    );
}

LiveAuction.propTypes = {
    data: PropTypes.array.isRequired,
}


export default LiveAuction;
