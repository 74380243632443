import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import avt from '../assets/images/slider/userAvatar.svg';
import button_capture from '../assets/images/icon/choose_photo.png';
import MetaMask from '../assets/images/icon/MetaMask.svg';
import { Image, Modal } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// ** Import utils
import { getApi, putApi, postApi } from '../utils/requestUtils';
import { showError, showSuccess } from '../components/toast';
import Loading from '../components/loading';
import { set, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { USER_API, API_FILE, WEB3_API } from '../components/api-url';
import { Copy, Trash } from 'react-bootstrap-icons';
import { ethers, BigNumber } from 'ethers';
import Web3 from 'web3';
import ContractABI from '../assets/abi/AirDropBySignature.json';
import AirdropData from '../assets/data/AirdropData.json';
import MyWallet from './Airdrop/MyWallet';
import OtherWallet from './Airdrop/OtherWallet';
const ContractAddress = process.env.REACT_APP_CONTRACT_AIRDROP_SIGNATURE;

const menuTab = [
  {
    class: 'active',
    name: 'My wallet'
  },
  {
    class: '',
    name: 'Other wallet'
  },
]


const ClainAirdropByTable = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [reload, setReaload] = useState(0)
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [searchAccount, setSearchAccount] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [tokenIds, setTokenIds] = useState('');
  const [contract, setContract] = useState(null);
  const [nftClaimed, setNftClaimed] = useState([]);
  const [isClaimed, setIsClaimed] = useState(true);
  const [gasEstimate, setGasEstimate] = useState(0);
  const [gasPrice, setGasPrice] = useState(0);
  const [gasFee, setGasFee] = useState(0);
  const [panelTab, setPanelTab] = useState(0)

  const [modalShow, setModalShow] = useState(false)

  const handleTabClick = (index) => {
    setPanelTab(index)
  }


  const renderTab = (index) => {
    switch (index) {
      case 0:
        return (
          <MyWallet />
        )
      case 1:
        return (
          <OtherWallet />
        )
    }
  }
  return (
    <div className='authors-2'>
      <Header />
      {isLoading && <Loading />}

      <section className="flat-title-page inner bg2">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Claim airdrop</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <div className="tf-section authors pt-5">
          <div className="themesflat-container">
            <div className="row ">
              <div className="themesflat-container">
                <div className="flat-tabs items">
                  <Tabs >
                    <TabList className="p-0">
                      {
                        menuTab.map((item, index) => (
                          <Tab
                            key={index}
                            onClick={() => handleTabClick(index)}
                          >
                            {item.name}
                          </Tab>
                        ))
                      }
                    </TabList>
                    <div className="content-tab mt-4">
                      {renderTab(panelTab)}
                    </div>

                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );

}

export default ClainAirdropByTable;