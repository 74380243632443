import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import ReactPlayer from 'react-player';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LiveAuction from '../components/layouts/LiveNft';
import { GAME_API } from '../components/api-url';

// ** Import utils
import { getApi, postApi } from '../utils/requestUtils';
import { showError, showSuccess } from '../components/toast';
import Loading from '../components/loading';
import CardModal from '../components/CardModal';

import logo from '../assets/images/icon/icon.png'
import linksvg from '../assets/images/icons/link.svg'
import { useDispatch } from 'react-redux';
import { addToCart } from '../actions/cartActions';

import LinkAddress from '../components/LinkAddress';

const ItemDetails02 = () => {

  const [isLoading, setIsLoading] = useState(false)

  const [itemDetail, setItemDetail] = useState({})

  const [queryParameters] = useSearchParams()
  const [liveAuctionData, setLiveAuctionData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  const handleAddToCart = (item) => {
    dispatch(addToCart(item));
  };

  const fetchAutictonData = async () => {
    const vendor = queryParameters.get('vendor');
    const collectionCode = queryParameters.get('collectionCode');
    
    setIsLoading(true);
    const res = await postApi(GAME_API.NFT_ITEM_SEARCH, {
      filter: [
        {
          id: 'vendor',
          value: vendor,
          op: 'eq'
        },
        {
          id: 'collectionCode',
          value: collectionCode,
          op: 'eq'
        }
      ],
      orderBy: 'createdAt',
      reverse: false,
      page: 1,
      limit: 8,
    });
    if (res.data) {
      setLiveAuctionData(res.data.rows);
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    const vendor = queryParameters.get('vendor');
    const collectionCode = queryParameters.get('collectionCode');
    const code = queryParameters.get('code');
    setIsLoading(true)
    getApi(`${GAME_API.NFT_ITEM_DETAIL}?vendor=${vendor}&collectionCode=${collectionCode}&code=${code}`).then(res => {
      if (res.data) {
        setItemDetail(res.data);
        setIsLoading(false);
      }
      setIsLoading(false);
    }).catch(err => {
      showError(err?.message);
      setIsLoading(false);
    })

    fetchAutictonData()

    // postApi(`${GAME_API.GAME_INTERACTION}`, { vendor: vendor, vendorGameCode: vendorGameCode, code: code })





  }, [queryParameters]);



  return (
    <div className='item-details'>
      <Header />
      {isLoading && <Loading />}
      
      <div className="tf-section tf-item-details style-2">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-5 col-md-12">
              <div className="content-left mt-2">
                <div className='mb-4 w-100 mt-20'>
                  <Link to="/list-nft" className="back-btn text-life3 heading-6">
                    <i className="fa fa-angle-left mr-1"></i> List Nft
                  </Link>
                </div>
                
                
                  {!isLoading && (
                    <div className="media mt-20">
                      {itemDetail?.assetType === 'IMAGE' && <img src={itemDetail?.assetUri} className='w-100' />}
                      {itemDetail?.assetType !== 'IMAGE' && (
                        <ReactPlayer
                          url={itemDetail?.assetUri}
                          width='100%'
                          playing={true}
                          controls={true}
                        />
                      )}
                    </div>
                  )}
                



              </div>
            </div>
            <div className="col-xl-7 col-md-12 mt-40">
              <div className="content-right" style={{marginTop: '10px'}}>
                <div className="sc-item-details">
                  <div className="meta-item">
                    <div className="left">
                      <h2> {itemDetail?.name} </h2>
                      <div className="page-title-heading mg-bt-12 mt-2">
                          <h5>
                            <span>Collection</span>
                            <Link to={`/collection/detail?vendor=${itemDetail?.vendor}&code=${itemDetail?.collectionCode}`} >
                            <span className='text-life3'> {itemDetail?.collectionName} </span>
                            
                            </Link>
                          </h5>
                      </div>
                    </div>
                    <div className="right mt-1 mb-4">
                      <Link to={itemDetail?.marketUrl} target='_blank' className='text-life3'>
                        Visit Marketplace
                        <img src={linksvg} alt="linksvg" className='ml-2' style={{marginTop: '-5px'}}/>
                      </Link>
                    </div>
                  </div>
                  <div className="client-infor sc-card-product">
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img style={{borderRadius: '50%'}} src={itemDetail?.collectionImage ? itemDetail?.collectionImage : logo} alt={itemDetail?.vendorName} />
                        </div>
                        <div className="info">
                          <span>Owned by</span>
                          <h6> 
                            {itemDetail?.contractDeployer && (
                              <LinkAddress address={itemDetail?.contractDeployer} />
                            )}
                            
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  

                  {itemDetail?.attributes && (
                    <div className="flat-tabs themesflat-tabs backgroud-detail" style={{marginBottom: 24}}>
                      <Tabs>
                        <TabList>
                          <Tab>Item’s Properties</Tab>
                        </TabList>

                        <TabPanel>
                          <ul className="bid-history-list">
                            {itemDetail.attributes.map((item, index) => (
                                <li key={index} >
                                  <div className="content">
                                    <div className="client">
                                      <div className="sc-author-box style-2">
                                        
                                        <div className="author-infor">
                                          <div className="name">
                                            <span> {item?.value?.trait_type}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="price">
                                      <h5>{item?.value?.value}</h5>
                                    </div>
                                  </div>
                                </li>
                              ))
                            }
                          </ul>
                        </TabPanel>
                      </Tabs>
                    </div>
                  )}

                  <div className="flat-tabs themesflat-tabs backgroud-detail" style={{marginBottom: 24}}>
                    <Tabs>
                      <TabList>
                        <Tab>Details</Tab>
                      </TabList>

                      <TabPanel>
                        <ul className="bid-history-list">
                          <li>
                            <div className="content">
                              <div className="client">
                                <div className="sc-author-box style-2">
                                  
                                  <div className="author-infor">
                                    <div className="name">
                                      <span> Contract Address </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="price">
                                <h5>{itemDetail?.address}</h5>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="content">
                              <div className="client">
                                <div className="sc-author-box style-2">
                                  
                                  <div className="author-infor">
                                    <div className="name">
                                      <span> Token ID </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="price">
                                <h5>{itemDetail?.tokenId}</h5>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="content">
                              <div className="client">
                                <div className="sc-author-box style-2">
                                  
                                  <div className="author-infor">
                                    <div className="name">
                                      <span> Token Standard </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="price">
                                <h5>{itemDetail?.collectionContract}</h5>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="content">
                              <div className="client">
                                <div className="sc-author-box style-2">
                                  
                                  <div className="author-infor">
                                    <div className="name">
                                      <span> Chain </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="price">
                                <h5> Ethereum </h5>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LiveAuction 
        data={liveAuctionData} 
        title="More NFTs from this Collection" 
      />
      <Footer />
      <CardModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}

export default ItemDetails02;
