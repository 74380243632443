import React , { useState , Fragment } from 'react';
import { Link } from 'react-router-dom'
import CardModal from '../CardModal';
import dstLogo from '../../../assets/images/icons/dst.svg'
import NftEmpty from '../../../assets/images/backgroup-secsion/NftEmpty.png'
import Pagination from '../../../components/Pagination';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import LinkAddress from '../../../components/LinkAddress';

const TodayPicks = props => {
    const data = props.data;
    const title = props?.title;
    const valueFilter = props?.valueFilter || {};
    const setValueFilter = props?.setValueFilter;
    const isShowMore = props?.isShowMore ? props?.isShowMore : false;
    const [searchKeyword, setSearchKeyword] = useState('');

    const [modalShow, setModalShow] = useState(false);
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        setValueFilter({ ...valueFilter, keyword: searchKeyword });
      }
    };
    return (
        <Fragment>
            <section className="tf-section today-pick">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                              <div className="heading-live-auctions" style={{marginBottom: 35}}>
                                  <div>
                                    {title && (
                                      <h2>
                                          {title}
                                      </h2>
                                    )}
                                    {!isShowMore && (
                                      <h2>
                                        <span className="color-9 ml-2 result-text" style={{fontSize: '14px'}}>
                                          RESULT
                                          <span
                                            style={{
                                              content: '',
                                              position: 'absolute',
                                              right: 0,
                                              top: '10%',
                                              transform: 'translateY(-5%)',
                                              height: '80%',
                                              borderRight: '1px solid #8A8AA0'
                                            }}
                                          />
                                        </span>
                                        <span className='ml-2' style={{fontSize: '20px'}}>
                                          {data?.count} 
                                        </span>
                                        <span className="color-9 ml-2" style={{fontSize: '14px'}}>
                                          NFTs
                                        </span>
                                      </h2>
                                    )}
                                  </div>
                                  {isShowMore && (
                                    <Link to="/list-nft" className="exp style2">EXPLORE MORE</Link>
                                  )}
                                  {!isShowMore && (
                                    <div className='widget widget-search mgbt-24' style={{float: 'right', width: '40%', minWidth: '300px'}}>
                                      <InputGroup>
                                        <Form.Control
                                          placeholder="Search name NFT"
                                          value={searchKeyword}
                                          onChange={(e) => setSearchKeyword(e.target.value)}
                                          style={{ height: '46px', maxWidth: 'calc(100% - 70px)'}}
                                          className='bg-primary-custom border-1'
                                          onKeyDown={handleKeyDown}
                                        />
                                        
                                        <Button 
                                          onClick={() => setValueFilter({ ...valueFilter, keyword: searchKeyword })}
                                          variant="outline-secondary"
                                        >
                                          <i className="icon-fl-search-filled ml-2 mr-2"></i>
                                        </Button>
                                      </InputGroup>
                                    </div>
                                  )}
                              </div>
                        </div>
                        <div className="col-md-12">
                          <div className='content-inner'>
                              {
                                data?.rows && data?.rows.map((item,index) => (
                                      <div key={index} className={`sc-card-product menu_card style-h7 ${item.feature ? 'comingsoon' : '' } `}>
                                          <div className="meta-info style">
                                              <div className="author">
                                                  <div className="avatar">
                                                    <img src={item?.collectionImage} alt={item.name} />
                                                  </div>
                                                  <div className="info">
                                                      
                                                    <Link to={`/collection/detail?vendor=${item?.vendor}&code=${item?.collectionCode}`} >
                                                      <h6>  {item?.collectionName} </h6>
                                                    </Link>
                                                      
                                                    <span>
                                                      <LinkAddress address={item?.contractDeployer} />  
                                                    </span>
                                                    
                                                  </div>
                                              </div>
                                              
                                          </div>
                                          <div className="card-media">
                                              <Link to={`/nft-details?vendor=${item?.vendor}&collectionCode=${item?.collectionCode}&code=${item?.code}`} >
                                                {item.assetType !== 'IMAGE' ? ( 
                                                  <embed src={item?.thumbnailUrl ? item?.thumbnailUrl : item?.assetUri} alt={item?.name} />
                                                ) : ( 
                                                  <img src={item?.thumbnailUrl ? item?.thumbnailUrl : NftEmpty} alt={item?.name} />
                                                )}
                                              </Link>
                                          </div>
                                          <div className="card-title text-center">
                                              <h5>
                                              <Link to={`/nft-details?vendor=${item?.vendor}&collectionCode=${item?.collectionCode}&code=${item?.code}`} >
                                                  {item.name ? item.name : '-'}
                                              </Link></h5>
                                          </div>
                                          <div className="meta-info">
                                              <div className="author">
                                                  <div className="info style2">
                                                      <span>Price</span>
                                                  </div>
                                              </div>
                                              <div className="price-item">
                                                {item?.price ? (
                                                  <span>
                                                    <img src={dstLogo} alt={item?.name} /> {item?.price} {item?.currency}
                                                  </span>
                                                  ) : (
                                                  <span>
                                                    -
                                                  </span>
                                                )}
                                              </div>
                                          </div>
                                          
                                      </div>
                                  ))
                              }
                          </div>
                          {data?.count > valueFilter?.limit && (
                            <Pagination
                              total={data?.count}
                              valueFilter={valueFilter}
                              setValueFilter={(value) => {
                                setValueFilter(value);
                              }}
                            />
                          )}
                        </div>
                    </div>
                </div>
            </section>
            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Fragment>
    );
}


export default TodayPicks;
