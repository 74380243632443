import React, { useState, useEffect, useRef } from 'react';

import { Image, Modal, Pagination } from 'react-bootstrap'

const PaginationComponent = ({ total, valueFilter, setValueFilter }) => {
  const { page, limit } = valueFilter;
  const totalPages = Math.ceil(total / limit);

  const getPages = () => {
    const pages = [];
    const maxVisiblePages = 6; 

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1); 

      if (page > 4) {
        pages.push('...'); 
      }

      const startPage = Math.max(2, page - 2);
      const endPage = Math.min(totalPages - 1, page + 2);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (page < totalPages - 3) {
        pages.push('...'); 
      }

      pages.push(totalPages); 
    }

    return pages;
  };
  return (
    <div className='pagination m-auto text-center'>
      
      <Pagination className="pl-2">
        <Pagination.Prev
          disabled={valueFilter?.page === 1}
          onClick={() => {
            setValueFilter({
              ...valueFilter,
              page: valueFilter?.page - 1
            })
          }}
        />

        {getPages().map((pageNumber, index) => (
          <Pagination.Item
            key={index}
            active={pageNumber === page}
            disabled={pageNumber === '...'}
            onClick={() => {
              if (pageNumber !== '...') {
                setValueFilter({
                  ...valueFilter,
                  page: pageNumber
                });
              }
            }}
          >
            {pageNumber}
          </Pagination.Item>
        ))}

        <Pagination.Next
          disabled={valueFilter?.page === Math.ceil(total / valueFilter.limit)}
          onClick={() => {
            setValueFilter({
              ...valueFilter,
              page: valueFilter?.page + 1
            })
          }}
        />
      </Pagination>
      
    </div>
  )
}

export default PaginationComponent;