const initialState = {
  CartCounter: 0,
};

const ReduxCartCounter = (state = initialState, action) => {
  const storedCartItems = JSON.parse(localStorage.getItem('shopping-cart')) || [];
  switch (action.type) {
    case "INCREMENT_CART_COUNTER":
      return { ...state, CartCounter: state.CartCounter + 1 };
    default:
      return storedCartItems.length;
  }
};

export default ReduxCartCounter;
