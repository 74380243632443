import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import routes from './pages/index'
import guest from './pages/guest'
import { isLoggedIn } from './utils/authUtils';
import { Toaster } from 'react-hot-toast'
import { store } from './store';
import { Provider } from 'react-redux';
import './configs/i18n';
import { postApi } from './utils/requestUtils';
import { USER_API } from './components/api-url';
import { showError } from './components/toast';

function App() {

  useEffect(() => {
    const fetchCheckIn = async () => {
      try {
        await postApi(USER_API.CHECKIN, {}).then((res) => { 
          if(res?.data){
            localStorage.setItem('checkIn', JSON.stringify({
              time: new Date().getTime(),
            }));
          }
        });
      } catch (error) {
        const today = new Date().toISOString().split('T')[0];
        await postApi(USER_API.CHECKIN_SEARCH, {
          "filter": [
            {
              "id": "code",
              "op": "eq",
              "value": today.split('-').join('')
            }
          ],
          "orderBy": "code",
          "reverse": false,
          "limit": 100,
          "page": 1
        }).then((res) => {
          if(res?.data?.count){
            localStorage.setItem('checkIn', JSON.stringify({
              time: new Date().getTime(),
            }));
          }else{
            showError('Failed to check in')
          }
        })
      }
    }
    const userIsLoggedIn = isLoggedIn();
    if (userIsLoggedIn) {
      const currentUtcDate = new Date().toISOString().split('T')[0];
      const storedCheckIn = JSON.parse(localStorage.getItem('checkIn'));
      if (!storedCheckIn) {
        fetchCheckIn()
      } else {
        const lastCheckInDate = new Date(storedCheckIn.time).toISOString().split('T')[0];
        if (lastCheckInDate !== currentUtcDate) {
          fetchCheckIn()
        }
      }
    }
  }, []);
  return (
    <Provider store={store}>
      <div id="toaster">
        <Toaster position="top-right" />
      </div>
      <Routes >
        {!isLoggedIn() && guest.map((data, index) => (
          <Route onUpdate={() => window.scrollTo(0, 0)} exact={true} path={data.path} element={data.component} key={index} />
        ))
        }
        {
          routes.map((data, index) => (
            <Route onUpdate={() => window.scrollTo(0, 0)} exact={true} path={data.path} element={data.component} key={index} />
          ))
        }
      </Routes>
    </Provider>
  );
}

export default App;
