import React from 'react';
import { Link } from 'react-router-dom';

function LinkAddress({ address, ...rest }) {
  
  if (!address) {
    return null;
  }
  const shortAddress = `${address.slice(0, 6)}...${address.slice(-4)}`;

  // OpenSea URL for the address
  const openSeaUrl = `https://opensea.io/${address}`;

  const handleLinkClick = (e) => {
    e.preventDefault();
    // Open the link in a new tab with no referrer
    window.open(openSeaUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <Link
      to={openSeaUrl}
      onClick={handleLinkClick}
      title={address} // Full address on hover
      {...rest}
    >
      {shortAddress}
    </Link>
  );
}

export default LinkAddress;