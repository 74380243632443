import React, { useState, useEffect } from 'react';
import Empty from '../../components/empty';

const NullTab = () => { 
  return (
    <div className='row'>
      <div className='col-12 mt-4'>
          <div className='bg-primary-custom b-r-12 p-2 pb-4'>
            <div className='row pt-3 pl-3 pr-3'>
              
              <div className='m-auto' style={{width: '210px'}}>
                <Empty />

              </div>
              
            </div>
          </div>
        </div>
    </div>
  )
}

export default NullTab;