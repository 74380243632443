import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap-accordion'
// import InputRange from 'react-input-range';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

const SideBar = ({ dataCate, onHandCate, priceRange, onValueChange }) => {
  return (
    <div id="side-bar" className="side-bar style-3 item">
      <div className="widget widget-filter style-1 mgbt-0">
        <div className="header-widget-filter">
          <h4 className="title-widget">Filter</h4>
          <h6
            className='color-primary pointer'
            id="clear-filter"
            onClick={() => {
              let newData = dataCate.map((item) => {
                item.content.map((itemm) => {
                  itemm.checked = '';
                })
                return item;
              })
              onHandCate(newData);
            }}
          >Reset</h6>
        </div>
      </div>
      <div className="divider"></div>
      <div className="wrap-category">
        {
          dataCate.map((item, index) => (
            <div key={index} className="widget widget-category boder-bt">
              <Accordion title={item.title} show={item.content.length}>
                <form action="#">
                  {
                    item.content.map((itemm, index) => (
                      <div key={index}>
                        <label>
                          <span>{itemm.field}</span>
                          <span className="pst-re">
                            <input
                              type="checkbox"
                              checked={itemm?.checked}
                              onChange={(e) => {
                                let newData = dataCate.map((item2) => {
                                  item2.content.map((itemm2) => {
                                    if (itemm2.field === itemm.field) {
                                      itemm2.checked = itemm?.checked ? '' : 'checked';
                                    }
                                  })
                                  return item2;
                                })
                                onHandCate(newData);
                              }}
                            />
                            <span className="btn-checkbox"></span>
                          </span>
                        </label><br />
                      </div>
                    ))
                  }
                </form>
              </Accordion>
            </div>
          ))
        }
        <div className="widget boder-b w-100 p-0">
          
          <Accordion title='Price Range' show={true}>
              <div className="row">
                <div className="col-12 mb-4">
                  <div className="row-form style-2">
                    <select className='input-sm form-select'>
                        <option value="DSP" selected>DSP</option>
                    </select>
                  </div>
                </div>
                <div className="col-5">
                  <input className='input-sm' size="sm" type="text" placeholder="Min" value={priceRange.min} onChange={(e) => onValueChange({ ...priceRange, min: e.target.value })} />
                </div>
                <div className='col-2 text-center d-flex align-items-center'>
                  <h6>to</h6>
                </div>
                <div className="col-5">
                  <input className='input-sm' size="sm" type="text" placeholder="Max" value={priceRange.max} onChange={(e) => onValueChange({ ...priceRange, max: e.target.value })} />
                </div>
                <div className="col-12 mt-2">
                  <button className="mt-3 mb-4 btn-default btn-primary">
                   <span> Apply </span>
                  </button>
                </div>
              </div>
          </Accordion>
        
         
        </div>

      </div>
    </div>
  );
}

export default SideBar;