import React from 'react';
import { Link } from 'react-router-dom'
import icon1 from '../../../assets/images/icon/Wallet.png'
import icon2 from '../../../assets/images/icon/Category.png'
import icon3 from '../../../assets/images/icon/Image2.png'
import icon4 from '../../../assets/images/icon/Bookmark.png'

const Create = () => {
    const data = [
        {
            title: "LIFE",
            description: "Empowering everyday activities with meaningful rewards. Life3 integrates seamlessly into your daily life, turning each interaction into an opportunity for growth and recognition.",
            icon : icon3,
            colorbg : "icon-color3"
        },
        {
            title: "WEB3",
            description: "Harnessing the power of blockchain technology to ensure transparency, security, and ownership of your digital assets. Life3 provides a decentralized platform where you control your data and achievements.",
            icon : icon2,
            colorbg : "icon-color2"
        },
        {
            title: "EARM",
            description: "Earn points, tokens, and exclusive NFTs by engaging in activities you love. With Life3, your efforts and time are valued, and rewards are designed to enrich your experience.",
            icon : icon1,
            colorbg : "icon-color1"
        },
        {
            title: "Community",
            description: "Join a vibrant community of like-minded individuals who share your passion for exploration and growth. Connect, collaborate, and celebrate each milestone together on Life3.",
            icon : icon4,
            colorbg : "icon-color4"
        },
    ]
    return (
        <section className="tf-box-icon live-auctions tf-section style7 bg-style">
        <div className="themesflat-container">
            <div className="row">
                <div className="col-xl-4 col-lg-12 col-md-12">
                    <div className="heading-live-auctions style2 mg-t-3 mg-bt-22">
                        <h3 className="heading-fill mg-bt-16">Creat NFT</h3>
                        <h2 className="tf-title text-left pb-15">Incentivize your lifestyle with Life3</h2>                    
                        <p className="content">Enjoy various Life3 dApps in our ecosystem and authenticate yourself in the cryptocurrency ecosystem
                        </p>     
                    </div>
                </div>                          
                <div className="col-xl-8 col-lg-12 col-md-12">
                    <div className="sc-box-icon-inner style3">
                        {
                            data.map((item,index) => (
                                <CreateItem key={index} item={item} />
                            ))
                        }
                    </div>  
                </div> 
            </div>                 
        </div>
    </section>
    );
}

const CreateItem = props => (
    <div className="sc-box-icon">
        <div className="image">
            <div className={`icon-create ${props.item.colorbg}`}>
                <img src={props.item.icon} alt="" />
            </div>                                                                           
        </div>
        <div className="wrap-box">
            <h3 className="heading"><Link to="/wallet-connect">{props.item.title}</Link></h3>
            <p className="content">{props.item.description}</p>
        </div>
    </div>
)

export default Create;
