import React, { useState } from 'react';
import Empty from '../../assets/images/backgroup-secsion/empty.png'

const EmptyView = () => { 
  return (
    <div className='row'>
      <div className='col-12 text-center p-2'>
        <img src={Empty} style={{ maxWidth: '50%' }} alt='empty' className="m-auto" />
        <h5 className='color-9 mt-3'>
          OPP! DATA EMPTY
        </h5>
      </div>
    </div>
  )
};

export default EmptyView;