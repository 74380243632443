import React , { useState , Fragment } from 'react';
import { Link } from 'react-router-dom'
import CardModal from '../CardModal';
import Pagination from '../../../components/Pagination';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import LinkAddress from '../../../components/LinkAddress';
import ColectionEmpty from '../../../assets/images/backgroup-secsion/ColectionEmpty.png'
const TodayPicks = props => {
    const data = props.data;
    const title = props?.title;
    const isShowMore = props?.isShowMore ? props?.isShowMore : false;
    const valueFilter = props?.valueFilter || {};
    const setValueFilter = props?.setValueFilter;
    const [searchKeyword, setSearchKeyword] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        setValueFilter({ ...valueFilter, keyword: searchKeyword });
      }
    };
    return (
        <Fragment>
            <section className="tf-section today-pick">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                              <div className="heading-live-auctions" style={{marginBottom: '35px'}}>
                                <div className='title-collection'>
                                    {title && (
                                      <h2>
                                          {title}
                                      </h2>
                                    )}
                                    {!isShowMore && (
                                      <h2>
                                        <span className="color-9 result-text" style={{fontSize: '14px'}}>
                                          RESULT
                                          <span
                                            style={{
                                              content: '',
                                              position: 'absolute',
                                              right: 0,
                                              top: '10%',
                                              transform: 'translateY(-5%)',
                                              height: '80%',
                                              borderRight: '1px solid #8A8AA0'
                                            }}
                                          />
                                        </span>
                                        <span className='ml-2' style={{fontSize: '20px'}}>
                                          {data?.count} 
                                        </span>
                                        <span className="color-9 ml-2" style={{fontSize: '14px'}}>
                                          Collections
                                        </span>
                                      </h2>
                                    )}
                                  </div>
                                  {isShowMore && <Link to="/collections" className="exp style2">EXPLORE MORE</Link>}
                                  
                                  {!isShowMore && (
                                    <div className='widget widget-search mgbt-24' style={{float: 'right', width: '40%', minWidth: '300px'}}>
                                      <InputGroup>
                                        <Form.Control
                                          placeholder="Search collection name"
                                          value={searchKeyword}
                                          onChange={(e) => setSearchKeyword(e.target.value)}
                                          style={{ height: '46px', maxWidth: 'calc(100% - 70px)'}}
                                          className='bg-primary-custom border-1'
                                          onKeyDown={handleKeyDown}
                                        />
                                        
                                        <Button 
                                          onClick={() => setValueFilter({ ...valueFilter, keyword: searchKeyword })}
                                          variant="outline-secondary"
                                        >
                                          <i className="icon-fl-search-filled ml-2 mr-2"></i>
                                        </Button>
                                      </InputGroup>
                                    </div>
                                  )}
                              </div>
                        </div>
                        <div className="col-md-12">
                          <div className='content-inner content-inner-collection'>
                              {
                                data?.rows && data?.rows.map((item,index) => (
                                      <div key={index} className={`sc-card-product menu_card style-h7 ${item.feature ? 'comingsoon' : '' } `}>
                                          
                                          <div className="card-media">
                                              <Link to={`/collection/detail?vendor=${item?.vendor}&code=${item?.code}`} >
                                                {item?.bannerImageUrl ? (
                                                  <img src={item?.bannerImageUrl} alt={item?.name} />
                                                ) : ( 
                                                  <img src={ColectionEmpty} alt={item?.name} />
                                                )}
                                                
                                              </Link>
                                          </div>
                                          <div className="meta-info style">
                                              <div className="author d-flex w-100">
                                                  <div className="avatar">
                                                    <img src={item.imageUrl} alt={item.name} />
                                                  </div>
                                                  <div className="info text-right mt-2">
                                                      <Link to={`/collection/detail?vendor=${item?.vendor}&code=${item?.code}`} >
                                                          <h5> {item.name ? item.name : '-'} </h5>
                                                      </Link>
                                                      <span className='mt-2'>
                                                        Creator by
                                                        <span className='ml-2'> 
                                                          <LinkAddress className='text-white' address={item?.contractDeployer} /> 
                                                        </span>
                                                      </span>
                                                      <h5>
                                                      
                                                    </h5>
                                                      
                                                  </div>
                                              </div>
                                              
                                          </div>
                                          
                                          
                                      </div>
                                  ))
                              }
                          </div>
                          {data?.count > valueFilter?.limit && (
                            <Pagination
                              total={data?.count}
                              valueFilter={valueFilter}
                              setValueFilter={(value) => {
                                setValueFilter(value);
                              }}
                            />
                          )}
                          
                        </div>
                    </div>
                </div>
            </section>
            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Fragment>
    );
}


export default TodayPicks;
