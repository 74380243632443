import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import {showError,showSuccess}  from '../components/toast';

import { USER_API } from '../components/api-url';


import axios from 'axios';

import { useForm } from 'react-hook-form'
import * as yup from 'yup'



import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
  password: yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
  passwordConfirm: yup.string().oneOf([yup.ref('password')], 'Passwords must match').required('Confirm Password is required'),
})

const Login = () => {

    const defaultValues = {
      password: '',
      passwordConfirm: '',
      email: window.localStorage.getItem('rememberEmail') ? window.localStorage.getItem('rememberEmail') : ''
    }
    
    const history = useNavigate();

    const {
      register,
      handleSubmit,
      formState: { errors }
    } = useForm({
      defaultValues,
      mode: 'onBlur',
      resolver: yupResolver(schema)
    })

    const onSubmit = async (params) => {
      axios.post(USER_API.REGISTER, params)
      .then(async () => {
        history('/login');
        showSuccess('Sign up successful, you can now login')
      })
      .catch(err => {
        showError(err.response?.data?.message)
      })
    };

    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Sign Up</h1>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            <section className="tf-login tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="tf-title-heading ct style-1">
                              Sign Up To Life3
                            </h2>
                            <div className="flat-form box-login-email">
                                

                                <div className="form-inner">
                                    <form noValidate onSubmit={handleSubmit(onSubmit)} id="contactform">
                                        <input className={`form-control ${errors.email ? 'is-invalid' : ''}`} {...register('email')} id="email" tabIndex="1"  aria-required="true" type="email" placeholder="Your Email Address" required />
                                        {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                        <input className={`form-control ${errors.password ? 'is-invalid' : ''}`} {...register('password')} id="password" tabIndex="2"  aria-required="true" type="password" placeholder="Your Password" required />
                                        {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                                        <input className={`form-control ${errors.passwordConfirm ? 'is-invalid' : ''}`} {...register('passwordConfirm')} id="passwordConfirm" tabIndex="2"  aria-required="true" type="password" placeholder="Confirm Password" required />
                                        {errors.passwordConfirm && <div className="invalid-feedback">{errors.passwordConfirm.message}</div>}
                                        
                                        <button className="submit" type="submit">Sign up</button>
                                    </form>
                                    <p className='text-center mt-4'>
                                      <span>You have an account?</span>
                                      <Link style={{marginLeft: '5px', fontWeight: 'bold'}}  to="/login">Sign in</Link>
                                    </p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Login;
