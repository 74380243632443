const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
        paths: [
          '/',
        ]
    },
    {
      id: 10,
      name: 'Airdrop',
      links: '/claim-airdrop-signature',
      paths: [
        '/claim-airdrop-signature',
      ]
    },
    {
        id: 11,
        name: 'Swap',
        links: '/point-swap',
        paths: [
          '/point-swap',
        ]
        
    },
    {
        id: 3,
        name: 'NFT',
        links: '/nft-marketplace',
        paths: [
          '/nft-marketplace',
          '/collections',
          '/list-nft',
          '/collection/detail'
        ],
        namesub: [
            {
                id: 1,
                sub: 'Overview',
                links: '/nft-marketplace',
            },
            {
                id: 2,
                sub: 'Collection',
                links: '/collections',
            },
            {
              id: 3,
              sub: 'List Nft',
              links: '/list-nft',
          }
      ]
    },
    
]
const menus2 = [
  {
    id: 1,
    name: 'Airdrop',
    links: '/',
    paths: [
      '/',
    ]
  },
  {
      id: 2,
      name: 'Swap',
      links: '/point-swap',
      paths: [
        '/point-swap',
      ]
      
  }
]
export default menus;