import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { USER_API } from '../components/api-url';

import authConfig from '../configs/auth';

import axios from 'axios';

import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { showError, showSuccess } from '../components/toast';
import { postApi } from '../utils/requestUtils';

import { yupResolver } from '@hookform/resolvers/yup'
import { jwtDecode } from 'jwt-decode'
import { signInWithPopup, signOut, OAuthProvider, getAuth, signInWithRedirect, getRedirectResult } from "firebase/auth";
import { auth, provider } from "../firebaseConfig";



const schema = yup.object().shape({
  email: yup.string().email('Email’s format must be valid').required('Email is required'),
  password: yup.string().required('Password is required')
})

const Login = () => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const defaultValues = {
    password: '',
    email: window.localStorage.getItem('rememberEmail') ? window.localStorage.getItem('rememberEmail') : '',
    rememberMe: window.localStorage.getItem('rememberEmail') ? true : false
  }

  const history = useNavigate();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })
  const fetchCheckIn = async () => {
    try {
      await postApi(USER_API.CHECKIN, {}).then((res) => { 
        if(res?.data){
          localStorage.setItem('checkIn', JSON.stringify({
            time: new Date().getTime(),
          }));
        }
      });
    } catch (error) {
      const today = new Date().toISOString().split('T')[0];
      await postApi(USER_API.CHECKIN_SEARCH, {
        "filter": [
          {
            "id": "code",
            "op": "eq",
            "value": today.split('-').join('')
          }
        ],
        "orderBy": "code",
        "reverse": false,
        "limit": 100,
        "page": 1
      }).then((res) => {
        if(res?.data?.count){
          localStorage.setItem('checkIn', JSON.stringify({
            time: new Date().getTime(),
          }));
        }else{
          showError('Failed to check in')
        }
      })
    }
  }

  
  const redirectSuccess = async () => { 
    await fetchCheckIn();
    const searchParams = new URLSearchParams(location.search);
      const redirectUrl = searchParams.get('redirect') ? decodeURIComponent(searchParams.get('redirect')) : '/';

      // Redirect to the specified URL or to the home page if no redirect URL is provided
      if (redirectUrl.startsWith('http')) {
        window.location.href = redirectUrl;
      } else {
        history(redirectUrl);
      }
  }
  const handleRedirectResult = async () => {
    try {
      const result = await getRedirectResult(auth);
      console.log(result)
      if (result?.user) {
        const idToken = await result.user.getIdToken();

        if (idToken) {
          axios.post(USER_API.LOGIN_APPLE, {
            idToken: idToken
          }).then(async response => {
            let bear_token = response.data?.data ? response.data?.data?.token : response.data?.token
            window.localStorage.setItem(authConfig.storageTokenKeyName, bear_token)
            let user = jwtDecode(bear_token)
            window.localStorage.setItem('userPath', user?.userPath)
            showSuccess('Login successful')
            redirectSuccess()
          })
            .catch(err => {
              showError(err.response?.data?.message)
            })
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleRedirectResult();
  }, [])

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const token = result._tokenResponse.oauthIdToken;
      axios.post(USER_API.LOGIN_GOOGLE, {
        token: token
      }).then(async response => {
        let bear_token = response.data?.data ? response.data?.data?.token : response.data?.token
        window.localStorage.setItem(authConfig.storageTokenKeyName, bear_token)
        let user = jwtDecode(bear_token)
        window.localStorage.setItem('userPath', user?.userPath)
        showSuccess('Login successful')
        redirectSuccess()
      })
        .catch(err => {
          showError(err.response?.data?.message)
        })
    } catch (error) {
      console.error("Login Failed:", error);
    }
  };
  const handleLoginApple = async () => {
    const provider2 = new OAuthProvider('apple.com');
    provider2.addScope('email');
    provider2.addScope('name');
    try {
      const result = await signInWithPopup(auth, provider2);
      if (result?.user) {
        const idToken = result._tokenResponse.oauthIdToken;
        if (idToken) {
          axios.post(USER_API.LOGIN_APPLE, {
            token: idToken
          }).then(async response => {
            let bear_token = response.data?.data ? response.data?.data?.token : response.data?.token
            window.localStorage.setItem(authConfig.storageTokenKeyName, bear_token)
            let user = jwtDecode(bear_token)
            window.localStorage.setItem('userPath', user?.userPath)
            showSuccess('Login successful')
            redirectSuccess()
          })
            .catch(err => {
              showError(err.response?.data?.message)
            })
        }
      }
    } catch (error) {
      console.error(error);
    }

  }


  const handleLogout = async () => {
    try {
      await signOut(auth);

    } catch (error) {
      console.error("Logout Failed:", error);

    }
  };


  const onSubmit = async (params) => {
    axios.post(USER_API.LOGIN, params)
      .then(async response => {
        params.rememberMe ? window.localStorage.setItem('rememberEmail', params.email) : window.localStorage.removeItem('rememberEmail')
        let bear_token = response.data?.data ? response.data?.data?.token : response.data?.token
        window.localStorage.setItem(authConfig.storageTokenKeyName, bear_token)
        let user = jwtDecode(bear_token)
        window.localStorage.setItem('userPath', user?.userPath)
        showSuccess('Login successful')
        redirectSuccess()
      })
      .catch(err => {
        showError(err.response?.data?.message)
      })
  };

  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Sign In</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-login tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <h2 className="tf-title-heading ct style-1">
                Sign In To Life3
              </h2>
              <div className="flat-form box-login-social">
                <div className="box-title-login">
                  <h5>Sign In with Google</h5>

                </div>
                <ul style={{ display: 'block' }}>

                  <li
                    style={{ width: '100%' }}
                  >

                    <button
                      className="sc-button style-2 fl-button pri-3"
                      onClick={handleLogin}
                    >

                      <i className="icon-fl-google-2"></i>
                      <span>Google</span>
                    </button>
                  </li>
                  <li
                    style={{ width: '100%', marginTop: '20px' }}
                  >
                    <button
                      className="sc-button style-2 fl-button pri-3"
                      onClick={handleLoginApple}
                    >

                      <i className="fa fa-apple-alt"></i>
                      <span>Apple ID</span>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="flat-form box-login-email">
                <div className="box-title-login">
                  <h5>Sign in with email</h5>
                </div>

                <div className="form-inner">
                  <form noValidate onSubmit={handleSubmit(onSubmit)} id="contactform">
                    <input className={`form-control ${errors.email ? 'is-invalid' : ''}`} {...register('email')} id="email" tabIndex="1" aria-required="true" type="email" placeholder="Your Email Address" required />
                    {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                    <input className={`form-control ${errors.password ? 'is-invalid' : ''}`} {...register('password')} id="password" tabIndex="2" aria-required="true" type="password" placeholder="Your Password" required />
                    {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                    <div className="row-form style-1">
                      <label>Remember me
                        <input type="checkbox" name="rememberMe" {...register('rememberMe')} />
                        <span className="btn-checkbox"></span>
                      </label>
                      <Link to="/forgot-password" className="forgot-pass">Forgot Password ?</Link>
                    </div>
                    <button className="submit" type="submit">Sign in</button>
                  </form>

                  <p className='text-center mt-4'>
                    <span>Don't have an account?</span>
                    <Link style={{ marginLeft: '5px', fontWeight: 'bold' }} to="/sign-up">Sign-up</Link>
                  </p>
                </div>

              </div>

            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Login;
