import React, { useState, useEffect, useRef } from 'react';
import { Image, Modal, Pagination } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// ** Import utils
import { getApi, putApi, postApi } from '../../utils/requestUtils';
import { showError, showSuccess } from '../../components/toast';
import Loading from '../../components/loading';
import MetaMask from '../../assets/images/icon/MetaMask.svg';
import { USER_API, API_FILE, WEB3_API } from '../../components/api-url';
import Web3 from 'web3';
import ContractABI from '../../assets/abi/AirDropBySignature.json';
import img4 from '../../assets/images/icon/connect-4.png'

// ** Import image
import IMGCLAIM from '../../assets/images/box-item/imageClaim.png';
import IMGSTARTCLAIM from '../../assets/images/box-item/imagestartclaim.png';
import NOAIRDROP from '../../assets/images/box-item/noairdrop.png';
import { BrowserProvider, Contract, formatUnits, BigNumber, ethers } from 'ethers'
import { mainnet, ethersConfig, projectId } from '../../configs/network';
import { createWeb3Modal, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';

const MESS = "Connect Life3 Portal to claim airdrop Hashed-snail nfts"

const valueFilterInit = {
  page: 1,
  limit: 50,
}
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true
});


const MyWallet = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [reload, setReload] = useState(0)
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [searchAccount, setSearchAccount] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [nftClaimed, setNftClaimed] = useState([]);
  const [isClaimed, setIsClaimed] = useState(true);
  const [gasEstimate, setGasEstimate] = useState(0);
  const [gasPrice, setGasPrice] = useState(0);
  const [gasFee, setGasFee] = useState(0);
  const [AirdropDatas, setAirdropDatas] = useState([]);
  const [valueFilter, setValueFilter] = useState(valueFilterInit);
  const [nftCount, setNftCount] = useState(0);
  const [AirdropBlock, setAirdropBlock] = useState(null);
  const [signer, setSigner] = useState(null);
  const { open, close , provider} = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  const loadWeb3 = async () => {
    await open();
  };


  useEffect(() => {
    if (isConnected && walletProvider) {
      (async () => {
        try {
          const web3Provider = new BrowserProvider(walletProvider);
          setWeb3(web3Provider);
          checkNetwork(web3Provider);
        } catch (error) {
          console.error('Error fetching address:', error);
        }
      })();
    }
  }, [isConnected, walletProvider]);

  useEffect(() => {
    setSelectedAccount(address);
    if(!address) {
      setWeb3(null);
    }
  }, [address]);

  const checkNetwork = async (web3) => {
    console.log('Checking network...', web3);
    updateAccounts(web3);
  };



  const updateAccounts = async (web3) => {
    if (!web3) return;
    handleSignatureRequest(web3, address.toLowerCase());

  };

  const handleSignatureRequest = async (web3Provider, account) => {
    const signer = await web3Provider.getSigner();
    setSigner(signer);
    const signature = await signer.signMessage("Connect Life3 Portal to claim airdrop Hashed-snail nfts");
    sendDataToBackend(signature, account, signer);

  }

  const sendDataToBackend = async (res, selectedAccount, signer) => {
    try {
      const response = await postApi(WEB3_API.NFT_ITEM_MIGRATION,
        {
          receiverAddress: selectedAccount,
          message: MESS,
          signedHex: res
        }
      );
      if (response?.data) {
        const dataConfig = response.data?.rows[0];
        setSearchAccount(selectedAccount);
        if (response.data) {
          setAirdropDatas(response.data?.rows);
          const contractInstance = new Contract(
            dataConfig?.airdropAddress,
            ContractABI,
            signer
          );
          setContract(contractInstance);
          setReload(reload + 1)
        }


      }
    } catch (error) {
      console.error('Error sending data to backend:', error);
    }
  }



  useEffect(() => {
    const AirdropData = AirdropDatas[0];
    if (AirdropData && selectedAccount && AirdropData?.airdropAddress) {

      const setBlockStatus = async () => {
        let tokensId = []
        AirdropDatas.map((item, index) => {
          tokensId.push(item?.tokenIds[0])
        })
        postApi(WEB3_API.GET_NFT_CLAIMED, {
          "filter": [
            {
              "id": "tokenId",
              "value": tokensId,
              "op": "in"
            }
          ],
          "orderBy": "tokenId",
          "reverse": false,
          "limit": 50,
          "page": 1,
        }).then((res) => {
          if (res?.data?.rows) {
            const newAirDropBlock = { ...AirdropBlock };
            res?.data?.rows.map(element => {
              if (element?.claimTnx) {
                newAirDropBlock[element?.tokenId] = true;
              } else {
                newAirDropBlock[element?.tokenId] = false;
              }
            });
            setAirdropBlock({ ...newAirDropBlock });
          }
        }).catch((error) => {
          console.error('Error fetching nft claimed', error);
        });

      }

      setBlockStatus();

    }

  }, [AirdropDatas, selectedAccount, reload]);



  // const handleClaim = async (AirdropData) => {
  //   if (AirdropData && AirdropData?.airdropAddress) {
  //     setIsLoading(true);
  //     const contract = new web3.eth.Contract(
  //       ContractABI,
  //       AirdropData?.airdropAddress
  //     );
  //     const proof = AirdropData?.merkleProof;
  //     const ids = AirdropData?.tokenIds;
  //     const snailInfos = AirdropData?.snailInfo.map(info => {
  //       // info[1] = info[1].toString();
  //       return info;
  //     })
  //     const v = AirdropData?.signerSignature?.v;
  //     const r = AirdropData?.signerSignature?.r;
  //     const s = AirdropData?.signerSignature?.s;
  //     var estimateGas = 0;
  //     var price = 0;

  //       try {
  //         estimateGas = await contract.methods.claim(proof, ids, snailInfos, v, r, s).estimateGas({
  //           from: selectedAccount,
  //         });
  //         price = await web3.eth.getGasPrice();
  //       } catch (error) {
  //         console.error('Error estimating gas:', error);
  //       }

  //     try {
  //       const txObject = {
  //         from: selectedAccount,
  //       };
  //       if (estimateGas) {
  //         txObject.gas = estimateGas;
  //       }
  //       if (price) {
  //         txObject.price = price;
  //       }

  //       const receipt = await contract.methods.claim(
  //         proof,
  //         ids,
  //         snailInfos,
  //         v,
  //         r,
  //         s
  //       ).send(txObject);
  //       setTimeout(() => { 
  //         setIsLoading(false);
  //         setReaload(reload + 1);
  //         showSuccess('Claim successful!');
  //       }, 5000);

  //     } catch (error) {
  //       setIsLoading(false);
  //       showError('Claim failed!');
  //       setReaload(reload + 1);
  //     }
  //   }
  // };


  const handleClaim = async (AirdropData) => {
    if (AirdropData && AirdropData?.airdropAddress) {
      setIsLoading(true);
      // const web3Provider = new BrowserProvider(walletProvider);
      // const signer = await web3Provider.getSigner();
      const contractNft = new Contract(
        AirdropData?.airdropAddress,
        ContractABI, 
        signer 
      );

      const proof = AirdropData?.merkleProof;
      const ids = AirdropData?.tokenIds;
      const snailInfos = AirdropData?.snailInfo.map(info => {
        info[1] = info[1].toString();
        return info;
      });
      const v = AirdropData?.signerSignature?.v;
      const r = AirdropData?.signerSignature?.r;
      const s = AirdropData?.signerSignature?.s;
      try {
        
        await contractNft.claim(proof, ids, snailInfos, v, r, s);

        setIsLoading(false);
        setReload(reload + 1);
        showSuccess('Claim successful!');
      } catch (error) {
        setIsLoading(false);
        showError('Claim failed!');
        setReload(reload + 1);
        console.error('Error claiming:', error);
      }
    }
  };


  useEffect(() => {
    if (searchAccount) {
      setIsLoading(true)
      postApi(WEB3_API.GET_NFT_CLAIMED, {
        "filter": [
          {
            "id": "ownerAddress",
            "value": searchAccount,
            "op": "eq"
          }
        ],
        "orderBy": "tokenId",
        "reverse": false,
        "limit": valueFilter?.limit,
        "page": valueFilter?.page,
      }).then((res) => {
        setIsLoading(false)
        setNftClaimed(res?.data?.rows || [])
        setNftCount(res?.data?.count || 0)
        if (res?.data?.rows) {
          let claimed = true;
          res?.data?.rows.map(element => {
            if (!element.claimTnx) {
              claimed = false;
            }
          })
          setIsClaimed(claimed)
        }
      }).catch((error) => {
        setIsLoading(false);
        console.error('Error fetching nft claimed', error);
      });
    }
  }, [reload, searchAccount, valueFilter])

  const handDisconnect = async () => { 
    await close();
  }

  return (
    <>
      <div className="bg-primary-custom b-r-12" style={{ marginTop: '30px', padding: '30px 20px' }}>
        {isLoading && <Loading />}
        {/* <input type="text"
            value={searchAccount}
            className="inputcopy mb-4"
            placeholder="Search..."
            onChange={(e) => setSearchAccount(e.target.value)}
        /> */}
        {!web3 && !selectedAccount && (
          <div className='text-center'>
            <h4 className='text-white'>Connect your wallet to check Hashed Snail NFT’s airdrop eligibility</h4>
            <p>
              <img src={IMGCLAIM} alt="claim" className='mt-4' />
            </p>
            <button type="button" className="sc-button bag fl-button pri-3 no-bg mt-4" onClick={loadWeb3}>
              <img src={img4} alt="metamask icon" style={{ width: '22px' }} className='mr-3 fl' />
              Wallet Connect
            </button>
          </div>
        )}
        {web3 && selectedAccount && AirdropDatas && (
          <div className='text-center'>
            <p>
              <img src={IMGSTARTCLAIM} alt="claim" className='mt-4' />
            </p>
            <h4 className='text-white mt-4'>Congratulations, You have total {AirdropDatas.reduce((accumulator, currentValue) => {
              if (Array.isArray(currentValue.tokenIds)) {
                return accumulator + currentValue.tokenIds.length;
              } else {
                return accumulator;
              }
            }, 0)
            } NFTs airdropped</h4>
            <h6 className='mt-4 color-9'>Your wallet address: {selectedAccount}</h6>
            <div className='d-flex justify-content-center mt-4'>
              <w3m-button
                size="md"
                label='Connect Wallet'
              />
            </div>
            
          </div>
        )}
        {web3 && selectedAccount && !AirdropDatas && (
          <div className='text-center'>
            <p>
              <img src={NOAIRDROP} alt="claim" className='mt-4' />
            </p>
            <h4 className='text-white mt-4'>Sorry, you did not receive the airdrop . Stay tuned for future opportunities and rewards!</h4>
            <h6 className='color-9 mt-4'>Your wallet address: {selectedAccount}</h6>
            <div className='d-flex justify-content-center mt-4'>
              <w3m-button
                size="md"
                label='Connect Wallet'
              />
            </div>
          </div>
        )}

      </div>
      {/* <div className='themesflat-container'>

        <button type="button" className="mt-4 btn-default btn-primar" onClick={handleClaim}>
          Claim All
        </button>
      </div>
      <div className='themesflat-container'>

        <button type="button" className="mt-4 btn-default btn-primar" onClick={() => {
          const contract = new web3.eth.Contract(ContractABI, ContractAddress);
          contract.methods.setApprovalForAll('0xDb82ECcb22D8eaA1F442E89aA1162549CF023fBE', true).send({ from: selectedAccount });
        }}>
          ApprovalForAll
        </button>
      </div> */}
      {web3 && selectedAccount && (
        <>
          <div className='d-flex' style={{ marginTop: '20px', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4 className='text-white mt-4'>Your NFTs</h4>
          </div>
          {AirdropBlock && AirdropDatas.map((item, index) => {
            return (
              <div className='b-r-12 d-flex bg-primary-custom' style={{ marginTop: '20px', padding: '10px', justifyContent: 'space-between', alignItems: 'center' }}>
                <h6 className='text-white short-text' style={{ width: 'calc(100% - 200px)' }}>
                  #{item?.tokenIds.join(', #')}
                </h6>
                <div className='themesflat-container' key={index} style={{ width: '200px' }}>
                  <button
                    disabled={AirdropBlock[item?.tokenIds[0]]}
                    type="button" className="btn-default btn-primar" onClick={() => handleClaim(item)}>
                    Claim {item?.tokenIds.length} NFTs
                  </button>
                </div>
              </div>
            )
          })}
          <div style={{ marginTop: '20px' }}>
            <Tabs >
              <TabPanel style={{ padding: '10px 0px' }}>
                <div className='w-100'>
                  {
                    nftClaimed.map((item, index) => (
                      <div key={index} className="card-airdrop bg-primary-custom b-r-12 float-left" style={{ margin: '10px' }}>
                        <div className="sc-card-product menu_card style-h7" style={{ background: 'none', margin: '0' }}>
                          <div className="card-title">
                            <h5 className='text-center'>
                              {item?.nftData?.name}

                            </h5>
                          </div>
                          <div className="card-media" style={{ minWidth: '100%', minHeight: '154px', backgroundColor: '#353541' }}>
                            <img src={item?.nftData?.externalUrl} alt={item?.nftData?.externalUrl} />

                          </div>

                          <div className="meta-info">

                            <div style={{ fontSize: '13px' }}><strong>Claimed: </strong> <strong className={item.claimTnx ? 'color-gradient' : 'text-danger'}> {item?.claimTnx ? 'Yes' : 'No'}</strong> </div>

                          </div>
                          <div className="w-100 mt-3" style={{ fontSize: '13px' }}>
                            <span style={{ color: '#7A798A' }}>Claimed time: </span>
                            {item.claimTnx ? new Date(item?.updatedAt).toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'long' }) : '-'}
                          </div>
                        </div>
                      </div>
                    ))
                  }

                </div>
                {nftCount > 0 && (
                  <div className='m-auto text-center mt-4 pagination'>
                    <Pagination className="px-4">
                      <Pagination.Prev
                        onClick={() => {
                          setValueFilter({
                            ...valueFilter,
                            page: valueFilter?.page - 1
                          })
                        }}
                      />
                      {Array.from({ length: Math.ceil(nftCount / valueFilter.limit) }, (_, index) => (
                        <Pagination.Item
                          key={index}
                          active={index + 1 === valueFilter?.page}
                          onClick={() => {
                            setValueFilter({
                              ...valueFilter,
                              page: index + 1
                            })
                          }}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}

                      <Pagination.Next
                        onClick={() => {
                          setValueFilter({
                            ...valueFilter,
                            page: valueFilter?.page + 1
                          })
                        }}
                      />
                    </Pagination>
                  </div>

                )}
              </TabPanel>
            </Tabs >
          </div>
        </>
      )}
    </>
  )
}

export default MyWallet;