
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Page404 = () => {
  return (
    <div className="error-template">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="error-content-template">
              <div className='pb-6'>
                <h1>
                  404
                </h1>
                <div className="error-details">
                  Page Not Found
                </div>
                <div className="error-actions mt-4 text-center">
                  <Link to="/">
                    <button className="mt-3 mb-4 btn-default btn-primary mg-t-15" type="button">
                      <span className="glyphicon glyphicon-home"></span>
                      Back to home
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page404;
