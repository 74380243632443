import React from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart, clearCart } from '../../actions/cartActions';
import { isLoggedIn } from '../../utils/authUtils';

const ShoppingCart = ({ cart, onClose }) => {
  const dispatch = useDispatch();

  const handleRemoveFromCart = (index) => {
    dispatch(removeFromCart(index));
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  if (cart.length !== 0) {
    return (
      <div className='CustomNavbar expanded navbar navbar-expand-xl navbar-light fixed-top'>
        <div className="navbar-collapse collapse show">
          <div
            type="button"
            className="CustomNavbar__close pointer icon-button-default"
            onClick={onClose}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" fill="currentColor" viewBox="0 0 256 256" >
              <rect width="256" height="256" fill="none"></rect>
              <line x1="200" y1="56" x2="56" y2="200" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></line>
              <line x1="200" y1="200" x2="56" y2="56" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></line>
            </svg>
          </div>

          <div className="ShoppingCart__top d-flex justify-content-between align-items-center me-3">
            <div className="ShoppingCart__top__count d-flex gap-2">
              <span className="Typography Typography--variant-bodyMd Typography--color-body-text-100 Typography--weight-bold">Items</span>
              <span className="Typography Typography--variant-bodyMd Typography--color-body-text-40 Typography--weight-regular">{cart.length}</span>
            </div>
            <div className="ShoppingCart__top__actions d-flex align-items-center gap-2" onClick={() => handleClearCart()}>
              <span className="Typography Typography--variant-bodyXs Typography--color-body-text-70 Typography--weight-regular ShoppingCart__clear pointer">
                <div>Clear All</div>
              </span>
              <div className="ShoppingCart__border">
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 256 256" className="ShoppingCart__refresh pointer">
                <rect width="256" height="256" fill="none"></rect>
                <polyline points="176.2 99.7 224.2 99.7 224.2 51.7" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></polyline>
                <path d="M190.2,190.2a88,88,0,1,1,0-124.4l34,33.9" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></path>
              </svg>
            </div>
          </div>

          <div className="ShoppingCart__items d-flex flex-column gap-2 mt-2 pb-3 w-100">
            {cart.map((item, index) => (
              <div className="ShoppingCartItem" key={index}>
                <div className="ShoppingCartItem__top d-flex align-items-center gap-md-2 gap-1">
                  <div className="ShoppingCartItem__preview position-relative">
                    <div className="PreviewImage ">
                      <img className="fade-animation fade-in" src={item.image} alt={item.name} loading="lazy" />
                    </div>
                  </div>
                  <div className="ShoppingCartItem__info w-100 d-flex flex-column">

                    <div className="ShoppingCartItem__name text-truncate text-start">
                      <span className="Typography Typography--variant-bodyXs Typography--variant-lg-bodyMd Typography--color-body-text-100 Typography--weight-bold">{item.name}</span>
                    </div>
                    <div className="ShoppingCartItem__collection">
                      <span className="pointer w-100 " data-testid="collection-info-modal-trigger">
                        <div className="CustomCollectionBox w-100">
                          <div className="CustomCollectionBox__collection d-flex w-100 whitelisted">
                            <div className="CustomCollectionBox__name text-truncate  pointer align-items-center d-flex">

                              <img src={item.gameImage} className="ms-1 mr-2" />
                              <span className="Typography Typography--variant-bodyXs Typography--color-body-text-100 Typography--weight-bold">{item.gameName}</span>
                            </div>
                          </div>
                        </div>
                      </span>
                      <div>
                      </div>
                    </div>
                  </div>
                  <div className="ShoppingCartItem__trash-icon d-flex align-items-center justify-content-center pointer" onClick={() => handleRemoveFromCart(index)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" fill="currentColor" viewBox="0 0 256 256" >
                      <rect width="256" height="256" fill="none"></rect>
                      <line x1="216" y1="56" x2="40" y2="56" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"></line>
                      <line x1="104" y1="104" x2="104" y2="168" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"></line>
                      <line x1="152" y1="104" x2="152" y2="168" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"></line>
                      <path d="M200,56V208a8,8,0,0,1-8,8H64a8,8,0,0,1-8-8V56" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"></path>
                      <path d="M168,56V40a16,16,0,0,0-16-16H104A16,16,0,0,0,88,40V56" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"></path>
                    </svg>
                  </div>
                </div>
                <div className="ShoppingCartItem__count-container d-flex align-items-center justify-content-between mt-2">
                  <div className="PercentageInput">
                    <div className="FormTextInput w-100 position-relative FormTextInput--start-icon-center FormTextInput--end-icon-center">
                      {/* <input type="number" min="1" max="100" id=":r4a:" data-testid="form-text-input" className="FormTextInput__input w-100 text-center" value={1} />
                      <label for=":r4a:" className="FormTextInput__icon-wrapper">
                        <span className="position-absolute d-flex FormTextInput__input-icon FormTextInput__input-icon--start FormTextInput__input-icon--start-disabled">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 256 256" className="pointer">
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M216,136H40a8,8,0,0,1,0-16H216a8,8,0,0,1,0,16Z"></path>
                          </svg>
                        </span>
                      </label>
                      <label for=":r4a:" className="FormTextInput__icon-wrapper">
                        <span className="position-absolute d-flex FormTextInput__input-icon FormTextInput__input-icon--end">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 256 256" className="pointer" >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M216,120H136V40a8,8,0,0,0-16,0v80H40a8,8,0,0,0,0,16h80v80a8,8,0,0,0,16,0V136h80a8,8,0,0,0,0-16Z"></path>
                          </svg>
                        </span>
                      </label> */}
                    </div>
                  </div>
                  <span className="PriceLabel ">
                    <span className="price-container">
                      <span className="price-color">100 DSP</span>
                    </span>
                  </span>
                </div>
                <div className="ShoppingCartItem__graph">
                </div>
              </div>
            ))}

          </div>


          <div className="mt-4 w-100 position-relative">
            <div className="ShoppingCart__shadow"></div>
            <div className="ShoppingCartSummary">
              <div className="ShoppingCartSummary__card">
                <div>
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex align-items-start justify-content-between">
                      <span className="Typography Typography--variant-bodySm Typography--color-body-text-100 Typography--weight-bold">Total</span>
                      <span className="PriceLabel ">
                        <span className="price-container">
                          <span className="price-color">1000 DSP</span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    {isLoggedIn() && ( 
                      <button
                      className="sc-button bag fl-button pri-3 no-bg w-100 mg-t-15"
                      >
                        Buy
                      </button>
                    )}
                    {!isLoggedIn() && (
                      <Link 
                        to={{
                          pathname: "/login",
                          search: `?ref=${window.location.href}`,
                        }}
                        className="text-center sc-button bag fl-button pri-3 no-bg w-100 mg-t-15"
                      >
                        Sign in
                      </Link>
                    )}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    )
  } else {
    return (
      <div className='CustomNavbar expanded navbar navbar-expand-xl navbar-light fixed-top'>
        <div className="navbar-collapse collapse show">
          <button
            type="button"
            className="CustomNavbar__close"
            onClick={onClose}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" fill="currentColor" viewBox="0 0 256 256" >
              <rect width="256" height="256" fill="none"></rect>
              <line x1="200" y1="56" x2="56" y2="200" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></line>
              <line x1="200" y1="200" x2="56" y2="56" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></line>
            </svg>
          </button>
          <div className="EmptyCart d-flex flex-column align-items-center position-relative py-5">
            <div className="EmptyCart__content px-3">
              <p className="Typography Typography--variant-bodyMonoXs Typography--color-body-text-70 Typography--weight-bold mb-1 text-uppercase">Your cart is empty</p>
              <h2 className="Typography Typography--variant-headerLg Typography--color-body-text-100 Typography--weight-bold">Explore top items, art and collectibles!</h2>
              
              <button
                data-testid="custom-button"
                className="sc-button bag fl-button pri-3 no-bg w-100 mg-t-15"
              >
                <span> Explore Market </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

};

export default ShoppingCart;