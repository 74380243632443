import React from 'react';
import logo from '../../assets/images/icon/icon.png'


const Loading = () => {
  return (
    <div className="u-loading">
      <div className="loader">
        <img src={logo} />
      </div>
    </div>
  );
};
export default Loading;


