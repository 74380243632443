import React, {useEffect, useState} from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import heroSliderData from '../assets/fake-data/data-slider';
import Slider from '../components/slider/Slider';
import Create from '../components/layouts/home-7/Create';
import TrendingNft from '../components/layouts/home/TrendingNft';

import { getApi, postApi } from '../utils/requestUtils';
import { GAME_API } from '../components/api-url';
import Loading from '../components/loading';

const initNftValueFilter = { 
  "type": [],
  "dapp": [],
  "filter": [],
  "orderBy": "createdAt",
  "keyword": "",
  "keywordBy": ["name"],
  "reverse": false,
  "page": 1,
  "limit": 8
}

const Home01 = () => {
    const [liveAuctionData, setLiveAuctionData] = useState([]);
    const [nftFilter, setNftFilter] = useState(initNftValueFilter);
    const [isLoading, setIsLoading] = useState(false)
    const fetchAutictonData = async () => {
      try {
        setIsLoading(true);
        const res = await postApi(GAME_API.NFT_ITEM_SEARCH, nftFilter)
        setIsLoading(false);
        if(res.data){
          setLiveAuctionData(res.data);

        }
      } catch (error) { 
        console.log(error)
        setIsLoading(false);
      }
    }
    useEffect(() => { 
      if(nftFilter){
        fetchAutictonData()
      }
    }, [nftFilter])

    return (
        <div className='home-7'>
            {isLoading && <Loading />}
            <Header />
            <Slider data={heroSliderData} />
            <TrendingNft 
              data={liveAuctionData}
              valueFilter={nftFilter}
              setValueFilter={(value) => setNftFilter(value)}
              title="Trending NFT"
              isShowMore={true}
            />
            <Create />
            <Footer />
        </div>
    );
}

export default Home01;
