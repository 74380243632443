// reducers/cartReducer.js
import { ADD_TO_CART, REMOVE_FROM_CART, UPDATE_QUANTITY, CLEAR_CART } from '../actions/types';

const initialState = {
  items: JSON.parse(localStorage.getItem('shopping-cart')) || []
};

const cartReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case ADD_TO_CART:
      const item = action.payload;
      const storedCartItems = JSON.parse(localStorage.getItem('shopping-cart'));
      if(storedCartItems){
        const newCartItems = [...storedCartItems, item];
        localStorage.setItem('shopping-cart', JSON.stringify(newCartItems));
      } else {
        localStorage.setItem('shopping-cart', JSON.stringify([item]));
      }
      return {
        ...state,
        items: JSON.parse(localStorage.getItem('shopping-cart'))
      };
    case REMOVE_FROM_CART:
      const removeItem = action.payload;
      const allItem = JSON.parse(localStorage.getItem('shopping-cart'));
      const newItem = []
      for(let i=0;i<allItem.length;i++){ 
        if(i != removeItem){
          newItem.push(allItem[i])
        }
      }
      localStorage.setItem('shopping-cart', JSON.stringify(newItem));
      return {
        ...state,
        items: [...newItem]
      };
    case UPDATE_QUANTITY:
      // Update item quantity logic
    case CLEAR_CART:
      localStorage.setItem('shopping-cart', JSON.stringify([]));
      return {
        ...state,
        items: []
      };
    default:
      return state;
  }
};

export default cartReducer;
