import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import avt from '../assets/images/slider/userAvatar.svg';
import button_capture from '../assets/images/icon/choose_photo.png';
import img4 from '../assets/images/icon/connect-4.png';
import { Image, Modal } from 'react-bootstrap'

// ** Import utils
import { getApi, putApi, postApi } from '../utils/requestUtils';
import { showError, showSuccess } from '../components/toast';
import Loading from '../components/loading';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { USER_API, API_FILE, WEB3_API } from '../components/api-url';
import { Copy, Trash } from 'react-bootstrap-icons';

import AvatarModal from '../components/AvatarModal';

import { BrowserProvider } from 'ethers'

import {mainnet, ethersConfig, projectId} from '../configs/network';
import { createWeb3Modal, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';



const initialData = {
  id: '',
  email: '',
  nickname: '',
  level: '1',
  exchangeRate: '',
  registeredIp: '',
  currencyId: '1',
  language: 'en',
  bio: '',
  facebook: '',
  twitter: '',
  discord: '',
}

const defaultValues = {
  password: '',
  newPassword: '',
  newPasswordConfirm: ''
}

const schema = yup.object().shape({
  // email: yup.string().email('Email’s format must be valid').required('Email is required'),
})

const schemaPassword = yup.object().shape({
  password: yup.string().min(8, 'Password must be at least 8 characters').required(),
  newPassword: yup
    .string()
    .min(8, 'New Password must be at least 8 characters')
    .notOneOf([yup.ref('password')], 'New password cannot be the same as the current password')
    .required('New Password is required'),
  newPasswordConfirm: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('newPassword')], 'Password must match')
})

createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true
});

const EditProfile = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [reload, setReaload] = useState(0)
  const [changeStatus, setChangeStatus] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [formData, setFormData] = useState(initialData)
  const [imgSrc, setImgSrc] = useState('')
  const [dataReset, setDataReset] = useState(initialData)
  const inputFileRef = useRef();
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccount] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [signatureRequestMessage, setSignatureRequestMessage] = useState(null);
  const [keySignature, setKeySignature] = useState(null);
  const { open, close} = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    initialData,
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })


  const loadWeb3 = async () => {
    await open();
  };

  useEffect(() => {
    console.log(isConnected && walletProvider && !formData?.userWalletAddres)
    if (isConnected && walletProvider && !formData?.userWalletAddres) {
      (async () => {
        try {
          const web3Provider = new BrowserProvider(walletProvider);
          const signer = web3Provider.getSigner();
          console.log(signer);
          setAccount(address);
          console.log('Connected account:', address);
          await checkNetwork(web3Provider);
        } catch (error) {
          console.error('Error fetching address:', error);
        }
      })();
    }
  }, [isConnected, walletProvider]);


  const checkNetwork = async (web3Provider) => {
    if (web3Provider) {
      const network = await web3Provider.getNetwork();
      console.log('Network:', network.chainId)
      // if (network.chainId !== 1) { // If not Ethereum main network (chain id 1)
      //   await window.ethereum.request({
      //     method: 'wallet_switchEthereumChain',
      //     params: [{ chainId: '0x1' }],
      //   }).catch((error) => {
      //     console.error('Error switching chain:', error);
      //   });
      // }

      updateAccounts(web3Provider);
    }
  };



  const updateAccounts = async (web3Provider) => {
    console.log('Updating accounts...');
    if (!web3Provider) return;
    setSelectedAccount(address);
    handleSignatureRequest(web3Provider, address);

  };

  const handleSignatureRequest = async (web3Provider, selectedAccount) => {
    await postApi(`${WEB3_API.GET_MESSAGE}`, {
      network: 'ethereum',
      chainId: '1', // Assuming chainId is 1 (Ethereum Mainnet)
      address: selectedAccount,
    }).then((res) => {
      setSignatureRequestMessage(res.data.message);
      if (res.data.key) {
        sendSignature(web3Provider, res.data.key, res.data.message, selectedAccount);
      } else {
        showError('No key found');
      }
    }).catch((err) => {
      showError(err?.response?.data?.message);
    });
  };

  const sendSignature = async (web3Provider, key, message, selectedAccount) => {
    if (!web3Provider || !selectedAccount) {
      console.error('Web3Provider not initialized or account not selected');
      return;
    }
    const signer = await web3Provider.getSigner();
    const signature = await signer.signMessage(message);
    sendDataToBackend(key, signature);
  };

  const sendDataToBackend = async (key, data) => {
    try {
      const response = await postApi(WEB3_API.CONFIRM, {
        key: key,
        signature: data,
      });
      if (response.data) {
        showSuccess('Link successfully');
        setReaload(reload + 1);
      }
    } catch (error) {
      console.error('Error sending data to backend:', error);
    }
  };




  const {
    register: registerChangePassword,
    handleSubmit: handleSubmitChangePassword,
    formState: { errors: errorsChangePassword },
    reset: reset,
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schemaPassword)
  })

  const fetchDataSource = () => {

    getApi(`${USER_API.PROFILE}`)
      .then((response) => {
        if (response.data) {
          let newData = response.data
          newData.language === null && (newData.language = 'en')
          setFormData(response.data)
          setDataReset(response.data)
          if (response.data?.profileImage) {
            setImgSrc(response.data?.profileImage)
          }
        }
      })
      .catch((err) => {
        showError(err?.response?.data?.message)
      })
      .finally(() => {
        setIsLoading(false);
      })
  }



  useEffect(() => {
    fetchDataSource()
  }, [reload])

  useEffect(() => {
    const isChanged = JSON.stringify(formData) !== JSON.stringify(dataReset);
    setChangeStatus(isChanged)
  }, [formData])

  const handleFormChange = (field, value) => {
    setFormData({ ...formData, [field]: value })
  }

  const onSubmit = async () => {
    setIsLoading(true)
    var newFormData = { ...formData }
    if (formData.nickname === null || dataReset.nickname) {
      newFormData = {
        currencyId: formData.currencyId,
        email: formData.email,
        id: formData.id,
        language: formData.language,
        bio: formData.bio,
        facebook: formData.facebook,
        twitter: formData.twitter,
        discord: formData.discord,
      }
    }

    await putApi(`${USER_API.PROFILE}`, newFormData)
      .then(() => {
        showSuccess('User profile was updated')
        setReaload(reload + 1)
      })
      .catch((err) => {
        showError(err?.response?.data?.message)
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const changePassword = async (data) => {
    setIsLoading(true)
    postApi(`${USER_API.CHANGE_PASSWORD}`, data)
      .then(() => {
        showSuccess('Password was updated')
        reset(defaultValues)
      })
      .catch(() => {
        showError('Failed to update password')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const [modalShow, setModalShow] = useState(false)

  const handleInputImageReset = () => {
    putApi(`${USER_API.PROFILE}`, {
      profileImage: ''
    })
      .then(() => {
        setInputValue('')
        setImgSrc('')
        showSuccess('Profile image was reset')
      })
      .catch((err) => {
        showError(err?.response?.data?.message)
      })
      .finally(() => {
        setIsLoading(false);
      })

  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(selectedAccount)
      .then(() => {
        showSuccess('Copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  const handleFileUpload = (event) => {
    try {
      setIsLoading(true);
      postApi(API_FILE.UPLOAD, {}).then((res) => {

        var url = res?.data?.uploadUrl
        var publicUrl = res?.data?.publicUrl

        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
          const blob = new Blob([reader.result], { type: file.type });
          const options = {
            method: 'PUT',
            body: blob
          }
          fetch(url, options)
            .then((response) => {

              if (response.ok) {
                // showSuccess('Profile updated successfully')
                putApi(`${USER_API.PROFILE}`, {
                  profileImage: publicUrl
                })
                  .then(() => {
                    // setImgSrc(file)
                    window.location.reload(false);
                    showSuccess('Profile image was updated')
                  })
                  .catch(() => {
                    showError('Failed to update profile image')
                  })
                  .finally(() => {
                    setIsLoading(false);
                  })

              } else {
                setIsLoading(false)
                showError('Failed to update profile image')
              }
            })
            .catch((error) => {
              setIsLoading(false);
              showError(error.message)
            })
        }
        reader.readAsArrayBuffer(file);
      })

    } catch (e) {
      setIsLoading(false);
      showError('Failed to update profile image')
    }
  };

  const unLinkWallet = async () => { 
    putApi(`${WEB3_API.UNLINK}`, {
      network: 'ethereum',
      chainId: chainId,
      address: formData?.userWalletAddress
    }).then(() => { 
      showSuccess('Unlink successfully')
      close();
      setReaload(reload + 1)
    })
  }


  return (
    <div>
      <Header />
      {isLoading && <Loading />}
      <section className="flat-title-page inner bg2">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Edit Profile</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="tf-create-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-2 col-lg-3 col-md-4 col-12">
              <div className="sc-card-profile text-center">
                
                  <div 
                    className="avatar rounded-full bg-transparent"
                  >
                    <Image src={imgSrc ? imgSrc + `?t=${new Date().getTime()}` : avt} alt="" responsive className="w-100" />
                  </div>
                <div id="upload-profile">
                  <img src={button_capture} alt="button_capture" className="button_capture" onClick={() => setModalShow(true)} />

                 
                </div>
                

              </div>
            </div>
            <div className="col-xl-10 col-lg-9 col-md-8 col-12">
              <div className="form-upload-profile" style={{ overflow: 'hidden' }}>

                <form className="form-profile" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-infor-profile">
                    <div className="info-account">
                      <h4 className="title-create-item">Account info</h4>
                      <fieldset>
                        <h4 className="title-infor-account">ID</h4>
                        <input value={formData.id} type="text" placeholder="ID" disabled required />
                      </fieldset>
                      <fieldset>
                        <h4 className="title-infor-account">User Name</h4>
                        <input type="text" value={formData.nickname} placeholder="Nickname" onChange={e => handleFormChange('nickname', e.target.value)} disabled={dataReset.nickname} />
                      </fieldset>
                      <fieldset>
                        <h4 className="title-infor-account">Email</h4>
                        <input type="email" value={formData.email} placeholder="Enter your email" required disabled />
                      </fieldset>
                      <fieldset>
                        <h4 className="title-infor-account">Bio</h4>
                        <textarea value={formData?.bio} tabIndex="4" rows="5" maxLength={100} onChange={e => handleFormChange('bio', e.target.value)} ></textarea>
                      </fieldset>
                    </div>
                    <div className="info-social">
                      <h4 className="title-create-item">Your Social media</h4>
                      <fieldset>
                        <h4 className="title-infor-account">Facebook</h4>
                        <input type="text" {...register('facebook')} placeholder="Facebook username" />
                        {errors.facebook && <div className="invalid-feedback-2">{errors.facebook.message}</div>}
                        <Link to="#" className="connect"><i className="fab fa-facebook"></i>Connect to facebook</Link>
                      </fieldset>
                      <fieldset>
                        <h4 className="title-infor-account">Twitter</h4>
                        <input type="text" placeholder="Twitter username"  {...register('twitter')} />
                        {errors.twitter && <div className="invalid-feedback-2">{errors.twitter.message}</div>}
                        <Link to="#" className="connect"><i className="fab fa-twitter"></i>Connect to Twitter</Link>
                      </fieldset>
                      <fieldset>
                        <h4 className="title-infor-account">Discord</h4>
                        <input type="text" placeholder="Discord username" {...register('discord')} />
                        {errors.discord && <div className="invalid-feedback-2">{errors.discord.message}</div>}
                        <Link to="#" className="connect"><i className="icon-fl-vt"></i>Connect to Discord</Link>
                      </fieldset>
                      <fieldset>
                        <h4 className='title-infor-account'>Web3 wallet</h4>
                        {!formData?.userWalletAddress && (
                          <button type="button" className="sc-button bag fl-button pri-3 no-bg mt-2" onClick={loadWeb3}>
                            <img src={img4} alt="metamask icon" style={{ width: '22px' }} className='mr-3 fl' />
                            Wallet Connect
                          </button>
                        )}
                        {formData?.userWalletAddress && (
                          <>
                            <input type="text" value={formData?.userWalletAddress} readOnly className="inputcopy" />
                            <button
                              type="button"
                              className="btn-copycode-profile"
                              onClick={copyToClipboard}
                            >
                              <Copy color="#fff" />
                            </button>
                            <button type="button" className="sc-button bag fl-button pri-3 no-bg" onClick={unLinkWallet}>
                              <i className="fa fa-trash mr-2" ></i>
                              Unlink Wallet
                            </button>
                          </>

                        )}

                      </fieldset>
                    </div>
                  </div>
                  <button className="sc-button bag fl-button pri-3 no-bg mg-t-15 float-right" type="submit">
                    <span>Update Profile</span>
                  </button>

                </form>

              </div>


              <div className="form-upload-profile form-profile">
                <form className="form-infor-profile" style={{ display: 'block' }} onSubmit={handleSubmitChangePassword(changePassword)}>
                  <div className="info-account">
                    <h4 className="title-create-item">Change password</h4>
                  </div>

                  <div className="row reset-confirm">
                    <div className="col-12 col-md-6">
                      <fieldset>
                        <h4 className="title-infor-account">Current Password</h4>
                        <input type="password" {...registerChangePassword('password')} placeholder="Current Password" />
                        {errorsChangePassword.password && <div className="invalid-feedback-2">{errorsChangePassword.password.message}</div>}
                      </fieldset>
                    </div>
                  </div>
                  <div className='row reset-confirm'>
                    <div className="col-12 col-md-6">
                      <fieldset>
                        <h4 className="title-infor-account">New Password</h4>
                        <input type="password" {...registerChangePassword('newPassword')} placeholder="New Password" />
                        {errorsChangePassword.newPassword && <div className="invalid-feedback-2">{errorsChangePassword.newPassword.message}</div>}
                      </fieldset>
                    </div>
                    <div className="col-12 col-md-6">
                      <fieldset>
                        <h4 className="title-infor-account">Confirm Password</h4>
                        <input type="password" placeholder="Confirm Password" {...registerChangePassword('newPasswordConfirm')} />
                        {errorsChangePassword.newPasswordConfirm && <div className="invalid-feedback-2">{errorsChangePassword.newPasswordConfirm.message}</div>}
                      </fieldset>
                    </div>
                  </div>

                  <button className="sc-button bag fl-button pri-3 no-bg mg-t-15 float-right" type="submit">
                    <span>Change Password</span>
                  </button>
                </form>
              </div>

            </div>


          </div>
        </div>
      </div>
      <AvatarModal
        image={imgSrc}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSuccess={() => {
          setModalShow(false);
          setReaload(reload + 1)
        }}
      />
      <Footer />
    </div>
  );
}

export default EditProfile;
