
import { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import { useSearchParams } from "react-router-dom";
import NftContent from './NftContent';
import SideBar from '../components/layouts/home-8/SideBar';
import { getApi, postApi } from '../utils/requestUtils';
import { GAME_API } from '../components/api-url';
import Footer from '../components/footer/Footer';
import TrendingNft from '../components/layouts/home/TrendingNft';
import Collections from '../components/layouts/nfts/Collections';
import Loading from '../components/loading';


const initCollectionValueFilter = { 
  "type": [],
  "dapp": [],
  "filter": [],
  "orderBy": "createdAt",
  "keyword": "",
  "keywordBy": ["name"],
  "reverse": false,
  "page": 1,
  "limit": 12
}

const Marketplace = () => {
  const [queryParameters] = useSearchParams()
  const [liveAuctionData, setLiveAuctionData] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [collectionDetail, setCollectionDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [collectionFilter, setCollectionFilter] = useState(initCollectionValueFilter)
  const formatNumber = (num) => { 
    if(!num) return 0;
    if (Number.isInteger(num)) {
      // Format the integer with commas
      return num.toLocaleString();
    } else {
      // Format the float with 4 decimal places
      return num.toFixed(4);
    }
  }
  const fetchCollectionData = async () => { 
    try {
      setIsLoading(true);
      const res = await postApi(GAME_API.NFT_COLLECTION_SEARCH, collectionFilter)
      setIsLoading(false);
      if(res.data){
        setCollectionData(res.data);
      }
    } catch (error) { 
      console.log(error)
      setIsLoading(false);
    }
  }
  
  const fetchCollectionDetail = async (vendor, code) => { 
    try {
      setIsLoading(true);
      const res = await getApi(GAME_API.NFT_COLLECTION_DETAIL, {vendor, code})
      setIsLoading(false);
      if(res.data){
        setCollectionDetail(res.data);
        console.log(res.data)
      }
    } catch (error) { 
      console.log(error)
      setIsLoading(false);
    }
  }

  useEffect(() => { 
    const vendor = queryParameters.get('vendor');
    const code = queryParameters.get('code');
    console.log(vendor, code)
    if(vendor && code) {
      fetchCollectionDetail(vendor, code)
    }
  }, [queryParameters]);

  useEffect(() => {
    if(collectionFilter){
      fetchCollectionData()
    }
  }, [collectionFilter]);
  

  return (
    <div className='home-7'>
      {isLoading && <Loading />}
      <Header />
      <section className="flat-title-page inner bg4">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Collections</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      
      <Collections 
        data={collectionData}
        title="All Collection"
        valueFilter={collectionFilter}
        setValueFilter={(value) => setCollectionFilter(value)}
        isShowMore={false}
      />

      <Footer />

    </div>
  );
}

export default Marketplace;