import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import avt from '../assets/images/slider/userAvatar.svg';
import button_capture from '../assets/images/icon/choose_photo.png';
import MetaMask from '../assets/images/icon/MetaMask.svg';
import { Image, Modal } from 'react-bootstrap'

// ** Import utils
import { getApi, putApi, postApi } from '../utils/requestUtils';
import { showError, showSuccess } from '../components/toast';
import Loading from '../components/loading';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { USER_API, API_FILE, WEB3_API } from '../components/api-url';
import { Copy, Trash } from 'react-bootstrap-icons';
import { ethers } from 'ethers';
import Web3 from 'web3';
import ContractABI from '../assets/abi/AirDropByTable.json'

const ContractAddress = process.env.REACT_APP_CONTRACT_AIRDROP_TABLE;

const ClainAirdropByTable = () => { 
  
  const [isLoading, setIsLoading] = useState(false)
  const [reload, setReaload] = useState(0)
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [tokenIds, setTokenIds] = useState('');
  const [contract, setContract] = useState(null);

  const loadWeb3 = async () => {
    setIsLoading(true)
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      setWeb3(window.web3);
      
      
      checkNetwork(window.web3);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);

    } else {
      console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
    setIsLoading(false)
  };

  const checkNetwork = async (web3) => {
    // if (web3) {
    //   const networkId = await web3.eth.net.getId();
      
    //   if (networkId !== '1') { // If not Ethereum main network (network id 1)
    //     await window.ethereum.request({
    //       method: 'wallet_switchEthereumChain',
    //       params: [{ chainId: '0x1' }], 
    //     }).catch((error) => {
    //       console.error('Error switching chain:', error);
    //       // Handle error (e.g., display a message to the user)
    //     });
    //   }
    // }
    updateAccounts(web3);
  };

  const updateAccounts = async (web3) => {
    if (!web3) return;
    const accounts = await web3.eth.getAccounts();
    setAccounts(accounts);
    let defaultAccount = accounts[0]; // Default to the first account

    for (let i = 0; i < accounts.length; i++) {
      const balance = await web3.eth.getBalance(accounts[i]);
      if (balance !== '0') {
        defaultAccount = accounts[i];
        break;
      }
    }
    setSelectedAccount(defaultAccount); // Select the first account by default
    const contractInstance = new web3.eth.Contract(
      ContractABI,
      ContractAddress
    );
    setContract(contractInstance);
  };

  const handleClaim = async () => {
    try {

        await contract.methods.claim(process.env.REACT_APP_NFT_CONTRACT).send({
          from: selectedAccount,
          gas: 500000,
          // arguments: JSON.parse(tokenIds)
        });
        showSuccess('Claim successful!');

      } catch (error) {
          console.error('Error claiming DOP:', error);
          showError('Failed to claim DOP. Please try again.');
      }
  };

  useEffect(() => {
    
  }, [reload])
  
  return (
    <div>
      <Header />
      {isLoading && <Loading />}
      <section className="flat-title-page inner bg2">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Claim airdrop</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="tf-create-item tf-section">
        <div className="row">
            <div className="col-12 text-center">
              {!selectedAccount && (
                <button type="button" className="sc-button bag fl-button pri-3 no-bg mt-2" onClick={loadWeb3}>
                  <img src={MetaMask} alt="metamask icon" style={{ width: '22px' }} className='mr-3 fl' />
                  Wallet Connect
                </button>
              )}
              {selectedAccount && (
                <div className='themesflat-container'>
                  
                  <button type="button" className="mt-4 sc-button bag fl-button pri-3 no-bg mt-2" onClick={handleClaim}>
                    Claim nfts
                  </button>
                </div>
              )}
            </div>
        </div>
      </div>

      <Footer />
    </div>
  );

}

export default ClainAirdropByTable;