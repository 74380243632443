import React, { useState, Fragment, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Countdown from "react-countdown";
import CardModal from '../CardModal';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../../actions/cartActions';
import { showError, showSuccess } from '../../toast';

import { getApi, postApi } from '../../../utils/requestUtils';
import { GAME_API } from '../../../components/api-url';
import Loading from '../../../components/loading';

const initValueFilter = {
  category: [],
  type: [],
  dapp: [],
  filter: [],
  orderBy: 'createdAt',
  reverse: false,
  page: 1,
  limit: 7,
}
const ItemContent = props => {
  const dataCate = props.dataCate;
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(100);
  const [filter, setFilter] = useState(initValueFilter);
  const [isLoading, setIsLoading] = useState(false)
  const fetchAutictonData = async () => {
      setIsLoading(true);
      let newFilter = []
      
      let dApps = dataCate[2].content.filter(item => item.checked === 'checked');
      
      if(dApps.length > 0){
        newFilter.push({
          id: 'vendor',
          value: dApps.map(item => item?.vendor),
          op: 'in'
        })
        newFilter.push({
          id: 'vendorGameCode',
          value: dApps.map(item => item?.vendorGameCode),
          op: 'in'
        })
      }
      const res = await postApi(GAME_API.GAME_ITEM_SEARCH,{
        ...filter,
        filter: [...newFilter]
      });
      if(res.data){
        let newData = [...data, ...res.data.rows];
        setData(newData);
        setTotal(res.data.count);
      }
      setIsLoading(false);
  }

  const handleScroll = () => {
    if (total <= data.length || window.innerHeight + document.documentElement.scrollTop <= document.documentElement.offsetHeight-20 || isLoading) {
      return;
    }
    if( filter.page * filter.limit < total){
      setFilter({...filter, page: filter.page + 1})
    }
  };
  useEffect(() => {
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading]);
  useEffect(() => { 
    setFilter({...filter, page: 1})
    setData([])
  }, [dataCate]);
  useEffect(() => { 
    if(filter){
      fetchAutictonData()
    }
  }, [filter]);

  const [dataTab] = useState(
    [
      {
        id: 1,
        title: "All",
      },
      {
        id: 2,
        title: "Today's Picks",
      },
      {
        id: 3,
        title: "On sale",
      },
    ]
  )
  const dispatch = useDispatch();

  const handleAddToCart = (item) => {
    dispatch(addToCart(item));
  };

  

  const listBtn = useRef(null)
  const gridBtn = useRef(null)
  const listContent = useRef(null)
  const gridContent = useRef(null)


  return (
    <Fragment>
      {isLoading && <Loading />}
      <div className="flat-tabs items">
        <Tabs >
          <TabList>
            {
              dataTab.map(data => (
                <Tab key={data.id}>{data.title}</Tab>
              ))
            }
          </TabList>
          {
            <TabPanel key='1'>
              <div className="option" >
                <h2 className="title">{total} Items</h2>
                <div className="view">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" className='btn-sort-by dropdown'>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 7H21" stroke="white" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M6 12H18" stroke="white" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M10 17H14" stroke="white" strokeWidth="1.5" strokeLinecap="round"></path>
                      </svg>
                      <span>Low To High</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ margin: 0 }}>
                      <Dropdown.Item href="#">
                        Top rate
                      </Dropdown.Item>
                      <Dropdown.Item href="#">
                        Mid rate
                      </Dropdown.Item>
                      <Dropdown.Item href="#">
                        Low rate
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </div>
              </div>

              <div className="content-item2 open" ref={gridContent}>
                {
                  data.map((item, index) => (
                    <div key={index} className="col-item">
                      <div className="sc-card-product menu_card style-h7">
                        <div className="wrap-media">
                          <div className="card-media">
                          <Link to={`/item-details?vendor=${item?.vendor}&vendorGameCode=${item?.vendorGameCode}&code=${item?.code}`}><img src={item.image} alt={item.name} /></Link>
                          </div>
                        </div>
                        <div className="card-title">
                          <p>Item's Name</p>
                          <h4>
                            <Link to={`/item-details?vendor=${item?.vendor}&vendorGameCode=${item?.vendorGameCode}&code=${item?.code}`}>"{item.name}"</Link>

                          </h4>
                        </div>
                        <div className="meta-info min-17 style">
                          <p>DAPP</p>
                          <div className="author">
                            <div className="avatar">
                              <img src={item.gameImage} alt="Axies" />
                            </div>
                            <div className="info">
                              <h4> <Link to="/authors-02">{item.gameName}</Link> </h4>
                            </div>
                          </div>
                        </div>
                        <div className="countdown">
                          <p>Countdown</p>
                          <div className="featured-countdown">
                            <div className="slogan float-left mr-2 mt-1"></div>
                            <Countdown date={Date.now() + 500000000}>
                              <span>You are good to go!</span>
                            </Countdown>
                          </div>
                        </div>

                        <div className="meta-info style">
                          <p>SOLD</p>
                          <div className="author">
                            <div className="info mt-2">
                              <h4> 500/1000 </h4>
                            </div>
                          </div>
                        </div>
                        <div className="meta-info style">
                          <p>Price</p>
                          <div className="author ">
                            <div className="info mt-2">
                              <h4> 10000 <strong className='color-gradient'> DSP </strong> </h4>
                            </div>
                          </div>
                        </div>
                        <div className="button-place-bid">
                          <button onClick={() => {
                            handleAddToCart(item);
                            showSuccess('Item added to cart');
                          }} data-toggle="modal" data-target="#popup_bid" className="sc-button style-place-bid style bag fl-button pri-3"><span>Add to cart</span></button>
                        </div>
                      </div>
                    </div>
                  ))
                }

              </div>

            </TabPanel>
          }
        </Tabs>
      </div>

    </Fragment>
  )
}

export default ItemContent