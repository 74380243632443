
import { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import { useSearchParams } from "react-router-dom";
import NftContent from './NftContent';
import SideBar from '../components/layouts/home-8/SideBar';
import { getApi, postApi } from '../utils/requestUtils';
import { GAME_API } from '../components/api-url';
import Footer from '../components/footer/Footer';
import TrendingNft from '../components/layouts/home/TrendingNft';
import Collections from '../components/layouts/nfts/Collections';
import Loading from '../components/loading';


const initNftValueFilter = { 
  "type": [],
  "dapp": [],
  "filter": [],
  "orderBy": "createdAt",
  "keyword": "",
  "keywordBy": ["name"],
  "reverse": false,
  "page": 1,
  "limit": 24
}

const ListNft = () => {
  const [queryParameters] = useSearchParams()
  const [liveAuctionData, setLiveAuctionData] = useState([]);
  const [nftFilter, setNftFilter] = useState(initNftValueFilter)
  const [isLoading, setIsLoading] = useState(false)
  const formatNumber = (num) => { 
    if(!num) return 0;
    if (Number.isInteger(num)) {
      // Format the integer with commas
      return num.toLocaleString();
    } else {
      // Format the float with 4 decimal places
      return num.toFixed(4);
    }
  }
  const fetchAutictonData = async () => {
    try {
      setIsLoading(true);
      const res = await postApi(GAME_API.NFT_ITEM_SEARCH, nftFilter)
      setIsLoading(false);
      if(res.data){
        setLiveAuctionData(res.data);

      }
    } catch (error) { 
      console.log(error)
      setIsLoading(false);
    }
    

}
  

  

useEffect(() => { 
  if(nftFilter){
    fetchAutictonData()
  }
}, [nftFilter]);
  

  return (
    <div className='home-7'>
      {isLoading && <Loading />}
      <Header />
      <section className="flat-title-page inner bg4">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">List Nft</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      
      <TrendingNft 
        data={liveAuctionData}
        title=""
        valueFilter={nftFilter}
        setValueFilter={(value) => setNftFilter(value)}
        isShowMore={false}
      />

      <Footer />

    </div>
  );
}

export default ListNft;