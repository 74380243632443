import React, { useState, useEffect } from 'react';
import st1 from '../../assets/images/icon/st1.svg';
import st2 from '../../assets/images/icon/st2.svg';
import st3 from '../../assets/images/icon/st3.svg';
import st4 from '../../assets/images/icon/st4.svg';
import man from '../../assets/images/backgroup-secsion/Illusman.png';

import { USER_API, FINANCE_API } from '../../components/api-url';
import { getApi, putApi, postApi } from '../../utils/requestUtils';


const Statistic = () => {
  const [stas, setStas] = useState(null)
  const [points, setPoints] = useState(0)
  const myPath = localStorage.getItem('userPath')
  const [path, setPath] = useState('')
  const formatNumber = (num) => { 
    if(!num) return 0;
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  useEffect(() => { 
    const params = new URLSearchParams(window.location.search);
    const pathParam = params.get('path');
    if (pathParam) {
      setPath(pathParam);
    }
    
    getApi(USER_API.GET_USER_STATISTICS + `?path=${pathParam}`).then(res => {
      if(res.data){
        setStas(res.data)
      }
    })
    getApi(FINANCE_API.GET_FINANCE).then(res => {
      if(res?.data){
        setPoints(res.data?.point)
      }
    })
  }, [])

  return (
    <>
      {myPath === path && 
        <div className='col-12 col-md-6 mt-3'>
          <div className='bg-primary-custom b-r-12 pl-2 pr-2'>
            <div className='row pt-3 pl-3 pr-3 '>
              <div className='col-7 d-flex ' style={{ alignItems: 'center', justifyContent: 'left' }}>
                <div className=''>
                  <h6 className='color-9 font-weight-normal'>
                    You have earned
                  </h6>
                  <h4 className="font-large mt-2"> {formatNumber(points)} <strong className="color-gradient"> DSP </strong> </h4>
                  <button type="button" className="align-items-center d-flex text-center btn-default btn-primary justify-content-center mt-3">
                    <i className="fa fa-eye mr-2" ></i>
                    View Activities
                  </button>
                </div>

              </div>
              <div className='col-5 text-center'>
                <img src={man} style={{ maxWidth: '100%' }} alt='point' className="m-auto" />
              </div>
            </div>
          </div>
        </div>
      }

      <div className='col-12 col-md-6 mt-3'>
        <div className='bg-primary-custom b-r-12 p-2 pb-4'>
          <div className='row pt-3 pl-3 pr-3'>
            <div className='col-12 pt-2 pb-2'>
              <h4 className='mt-2 font-weight-normal'>STATISTICS</h4>
            </div>
            <div className='col-6 mt-4 mb-4'>
              <div className='d-flex'>
                <div className='mr-4'>
                  <img src={st1} alt='st1' />
                </div>
                <div>
                  <h6 className='color-9 font-weight-normal mb-2'>
                    Item Owned
                  </h6>
                  <h4> {formatNumber(stas?.itemOwned)} </h4>
                </div>
              </div>

            </div>

            <div className='col-6 mt-4 mb-4'>
              <div className='d-flex'>
                <div className='mr-4'>
                  <img src={st2} alt='st1' />
                </div>
                <div>
                  <h6 className='color-9 font-weight-normal mb-2'>
                    NFT Owned
                  </h6>
                  <h4> {formatNumber(stas?.nftOwned)} </h4>
                </div>
              </div>

            </div>

            <div className='col-6 mt-4'>
              <div className='d-flex'>
                <div className='mr-4'>
                  <img src={st3} alt='st1' />
                </div>
                <div>
                  <h6 className='color-9 font-weight-normal mb-2'>
                    Spent
                  </h6>
                  <h4> 
                    {formatNumber(stas?.totalSpent)}
                    <strong className="color-gradient"> DSP </strong>
                  </h4>
                </div>
              </div>

            </div>

            <div className='col-6 mt-4'>
              <div className='d-flex'>
                <div className='mr-4'>
                  <img src={st4} alt='st1' />
                </div>
                <div>
                  <h6 className='color-9 font-weight-normal mb-2'>
                    Earned
                  </h6>
                  <h4> 
                    {formatNumber(stas?.totalEarned)}
                    <strong className="color-gradient"> DSP </strong>
                  </h4>
                </div>
              </div>

            </div>
            <div className='p-3'></div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Statistic;