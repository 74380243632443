// actions/cartActions.js
import { ADD_TO_CART, REMOVE_FROM_CART, UPDATE_QUANTITY, CLEAR_CART } from './types';

export const addToCart = (item) => {
  return {
    type: ADD_TO_CART,
    payload: item
  };
};

export const removeFromCart = (itemId) => {
  return {
    type: REMOVE_FROM_CART,
    payload: itemId
  };
};

export const updateQuantity = (itemId, newQuantity) => {
  return {
    type: UPDATE_QUANTITY,
    payload: { itemId, newQuantity }
  };
};

export const clearCart = () => {
  return {
    type: CLEAR_CART
  };
};
