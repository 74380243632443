import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Link } from 'react-router-dom'
import Countdown from "react-countdown";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LiveAuction from '../components/layouts/LiveAuction';
import { GAME_API } from '../components/api-url';

// ** Import utils
import { getApi, postApi } from '../utils/requestUtils';
import { showError, showSuccess } from '../components/toast';
import Loading from '../components/loading';

import img1 from '../assets/images/avatar/avt-3.jpg'
import img2 from '../assets/images/avatar/avt-11.jpg'
import img3 from '../assets/images/avatar/avt-1.jpg'
import img4 from '../assets/images/avatar/avt-5.jpg'
import img5 from '../assets/images/avatar/avt-7.jpg'
import img6 from '../assets/images/avatar/avt-8.jpg'
import logo from '../assets/images/icon/icon.png'
import { useDispatch } from 'react-redux';
import ReactApexCharts from 'react-apexcharts';
import { addToCart } from '../actions/cartActions';

const ItemDetails02 = () => {

  const [isLoading, setIsLoading] = useState(false)

  const [dataHistory] = useState(
    [
      {
        img: img1,
        name: "Mason Woodward",
        time: "8 hours ago",
        price: "4.89 DSP",
        priceChange: "$12.246"
      },
      {
        img: img2,
        name: "Mason Woodward",
        time: "at 06/10/2021, 3:20 AM",
        price: "4.89 DSP",
        priceChange: "$12.246"
      },
      {
        img: img3,
        name: "Mason Woodward",
        time: "8 hours ago",
        price: "4.89 DSP",
        priceChange: "$12.246"
      },
      {
        img: img4,
        name: "Mason Woodward",
        time: "8 hours ago",
        price: "4.89 DSP",
        priceChange: "$12.246"
      },
      {
        img: img5,
        name: "Mason Woodward",
        time: "8 hours ago",
        price: "4.89 DSP",
        priceChange: "$12.246"
      },
      {
        img: img6,
        name: "Mason Woodward",
        time: "8 hours ago",
        price: "4.89 DSP",
        priceChange: "$12.246"
      },
    ]
  )
  const [itemDetail, setItemDetail] = useState({})

  const [queryParameters] = useSearchParams()
  const [liveAuctionData, setLiveAuctionData] = useState([]);

  const dispatch = useDispatch();

  const handleAddToCart = (item) => {
    dispatch(addToCart(item));
  };

  const fetchAutictonData = async () => {
    setIsLoading(false);
    const res = await getApi(GAME_API.GAME_ITEM_TRENDING);
    if(res.data){
      setLiveAuctionData(res.data);
      setIsLoading(false);
    }
  }
  
  useEffect(() => { 
    const vendor = queryParameters.get('vendor');
    const vendorGameCode = queryParameters.get('vendorGameCode');
    const code = queryParameters.get('code');
    setIsLoading(true)
    getApi(`${GAME_API.GAME_ITEM_DETAIL}?vendor=${vendor}&vendorGameCode=${vendorGameCode}&code=${code}`).then(res => {
      if(res.data){
        setItemDetail(res.data);
        setIsLoading(false);
      }
    
    }).catch(err => { 
      showError(err?.message);
      setIsLoading(false);
    })
    
    fetchAutictonData()

    postApi(`${GAME_API.GAME_INTERACTION}`, {vendor: vendor, vendorGameCode: vendorGameCode, code: code})





  }, [queryParameters]);

  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false
      }
    },
    colors: ['#01BFFF'], 
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
      style: {
        fontSize: '15px', // Adjust font size if needed
        colors: '#fff' // Set color for labels
      },
      labels: {
        style: {
          colors: '#fff' // Set color for x-axis labels
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#fff' // Set color for y-axis labels
        }
      }
    }
  };
  
  const series = [
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91]
    }
  ];

  return (
    <div className='item-details'>
      <Header />
      {isLoading && <Loading />}
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Item Details</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="tf-section tf-item-details style-2">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="content-left">
                <div className="media">
                  <img src={itemDetail?.image} alt="Axies" className='w-100'/>
                </div>

                <div className="row mt-4">
                  <div className='col-sm-12 col-md-4 '>
                    <div className='backgroud-detail py-3 d-flex justify-content-between align-items-center mb-2'>
                      <div className='card-media'> <span> Current Sold </span> </div>
                      <div> <span> 500/1000 </span> </div>
                    </div>

                  </div>


                  <div className='col-sm-12 col-md-8'>
                    <div className='backgroud-detail d-flex justify-content-between align-items-center'>
                      <div className="item meta-price">
                        <span className="heading">Sale ends March 2,2024 5:31 PM</span>
                      </div>
                      <div className="card-media">
                        <div className='featured-countdown d-flex align-items-center'>
                          <div className="slogan" style={{height: '15px'}}></div>
                          <Countdown date={Date.now() + 500000000}>
                            <span>You are good to go!</span>
                          </Countdown>
                        </div>
                        
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-12 mt-4'>
                    <div className="base-frame w-100 padding-frame">
                      <div className="base-frame-title mb-3">
                          <h4> ITEM’S PROPERTIES </h4>
                      </div>
                      <div className='py-3 d-flex justify-content-between'>
                        <h5> Property 1 </h5>
                        <h5> TBD </h5>
                      </div>
                      <div className='py-3 d-flex justify-content-between'>
                        <h5> Property 2 </h5>
                        <h5> TBD </h5>
                      </div>
                    </div>
                  </div>

                </div>


              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="content-right">
                <div className="sc-item-details">
                  <div className="meta-item">
                    <div className="left">
                      <h2> {itemDetail?.name} </h2>
                    </div>
                    <div className="right">
                      <span className="viewed eye mg-r-8">{itemDetail?.totalView}</span>
                      <span to="/login" className="liked heart wishlist-button"><span className="number-like">100</span></span>
                    </div>
                  </div>
                  <div className="client-infor sc-card-product">
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img src={logo} alt="life3" />
                        </div>
                        <div className="info">
                          <span>Vendor</span>
                          <h6> Life3 </h6>
                        </div>
                      </div>
                    </div>
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img src={itemDetail?.gameImage} alt={itemDetail?.gameName} />
                        </div>
                        <div className="info">
                          <span>Dapp</span>
                          <h6> <Link to="/author-02">{itemDetail?.gameName}</Link> </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='item-order'>
                    <div className="row align-items-center">
                      <div className="col-md-6 col-sm-12">
                        <h5 className='mb-4 color-text-9'>Item’s Price</h5>
                        <h4 className='font-large'>1000 <strong className='color-gradient'> DSP </strong> </h4>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <button className="mt-3 mb-4 btn-default btn-primary">

                          <i className="fa fa-shopping-cart mr-2"></i> <span> Buy Now </span>

                        </button>
                        <button 
                        type="button"
                        onClick={() => {
                          handleAddToCart(itemDetail);
                          showSuccess('Item added to cart');
                        }}
                        className="w-100 mb-3 sc-button loadmore style bag fl-button pri-3"><span>Add To Cart</span></button>
                      </div>
                    </div>
                  </div>

                  <div className="item-order mt-4">
                    <ReactApexCharts options={options} series={series} type="bar" height={280} />
                  </div>

                  <div className="flat-tabs themesflat-tabs backgroud-detail mt-4">
                    <Tabs>
                      <TabList>
                        <Tab>Recent purchase</Tab>
                      </TabList>

                      <TabPanel>
                        <ul className="bid-history-list">
                          {
                            dataHistory.map((item, index) => (
                              <li key={index} item={item}>
                                <div className="content">
                                  <div className="client">
                                    <div className="sc-author-box style-2">
                                      <div className="author-avatar">
                                        <Link to="#">
                                          <img src={item.img} alt="Axies" className="avatar" />
                                        </Link>
                                        <div className="badge"></div>
                                      </div>
                                      <div className="author-infor">
                                        <div className="name">
                                          <h6><Link to="/authors-02">{item.name} </Link></h6> <span> purchased 2 items</span>
                                        </div>
                                        <span className="time">{item.time}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="price">
                                    <h5>{item.price}</h5>
                                  </div>
                                </div>
                              </li>
                            ))
                          }
                        </ul>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LiveAuction data={liveAuctionData} title="More Items from this Dapp" />
      <Footer />
    </div>
  );
}

export default ItemDetails02;
