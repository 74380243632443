const API_HOST = process.env.REACT_APP_PUBLIC_API_HOST;

const USER_API = {
  LOGIN: `${API_HOST}/api/user-public/login`,
  REGISTER: `${API_HOST}/api/user-public/registration`,
  LOGOUT: `${API_HOST}/api/user/logout`,
  FORGOT_PASSWORD: `${API_HOST}/api/user/forgot-password`,
  PASSWORD_RECOVERY: `${API_HOST}/api/user-public/password-recovery`,
  PASSWORD_RECOVERY_VERIFY: `${API_HOST}/api/user-public/password-recovery/verify`,
  PASSWORD_RECOVERY_RESET: `${API_HOST}/api/user/create-password`,
  RESET_PASSWORD: `${API_HOST}/api/user/reset-password`,
  PROFILE: `${API_HOST}/api/user/profile`,
  PROFILE_NFT: `${API_HOST}/api/user/profile/nft`,
  VERIFY_EMAIL: `${API_HOST}/api/user/verify-email`,
  GET_USER: `${API_HOST}/api/user/user`,
  UPDATE_USER: `${API_HOST}/api/user/user`,
  UPDATE_PASSWORD: `${API_HOST}/api/user/password`,
  GET_USERS: `${API_HOST}/api/user/users`,
  CHANGE_PASSWORD: `${API_HOST}/api/user/profile/change-password`,
  GET_USER_BY_ID: (id) => `${API_HOST}/api/user/${id}`,
  DELETE_USER: (id) => `${API_HOST}/api/user/${id}`,
  API_KEY: `${API_HOST}/api/user/api-key`,
  API_LIST: `${API_HOST}/api/user/api-key/search`,
  API_KEY_EDIT: (id) => `${API_HOST}/api/user/api-key/${id}`,
  CALL_BACK_URL: `${API_HOST}/api/user/callback`,
  GET_USER_PROFILE: `${API_HOST}/api/public/user/profile`,
  GET_USER_STATISTICS: `${API_HOST}/api/public/user/stats/assets`,
  LOGIN_GOOGLE: `${API_HOST}/api/user-public/sso/google`,
  LOGIN_APPLE: `${API_HOST}/api/user-public/sso/apple`,
  CHECKIN: `${API_HOST}/api/reward/daily-checkin`,
  CHECKIN_SEARCH: `${API_HOST}/api/reward/daily-checkin/search`,
}

const RESULTS_API = {
  GET_RESULTS: `${API_HOST}/api/rng/random-number/search`,
  GET_STATISTICAL_DATA: `${API_HOST}/api/rng/random-number/stats`,
  GET_RESULTS_BY_ID: (id) => `${API_HOST}/api/rng/${id}`,
  DELETE_RESULTS: (id) => `${API_HOST}/api/rng/${id}`,
};

const API_FILE = {
  UPLOAD: `${API_HOST}/api/user/profile/upload-profile-image`,
};

const GAME_API = {
  GAME_ITEM_TRENDING: `${API_HOST}/api/public/game-item/trending`,
  GAME_ITEM_DETAIL: `${API_HOST}/api/public/game-item/detail`,
  GAME_INTERACTION: `${API_HOST}/api/public/interaction/view/game-item`,
  GAME_ITEM_SEARCH: `${API_HOST}/api/public/game-item/search`,
  GAME_SEARCH: `${API_HOST}/api/public/game/search`,
  NFT_ITEM_SEARCH: `${API_HOST}/api/public/nft-item/search`,
  NFT_ITEM_DETAIL: `${API_HOST}/api/public/nft-item/detail`,
  NFT_ONCHAIN_SEARCH: `${API_HOST}/api/public/nft-item/on-chain/search`,
  NFT_COLLECTION_SEARCH: `${API_HOST}/api/public/nft-collection/search`,
  NFT_COLLECTION_DETAIL: `${API_HOST}/api/public/nft-collection/detail`,
};

const GAME_INTERACTION_API = {
  INTERACTION: `${API_HOST}/api/public/interaction/view/game-item`,
  INTERACTION_SEARCH: `${API_HOST}/api/public/interaction/search`,
};

const FINANCE_API = {
  GET_FINANCE: `${API_HOST}/api/finance/balance/point`,
  SEARCH_FINANCE: `${API_HOST}/api/finance/ledger/point/search`,
  GET_FINANCE_BY_ID: (id) => `${API_HOST}/api/finance/${id}`,
  DELETE_FINANCE: (id) => `${API_HOST}/api/finance/${id}`,
};

const WEB3_API = {
  GET_MESSAGE: `${API_HOST}/api/user-wallet/link/attach`,
  CONFIRM: `${API_HOST}/api/user-wallet/link/attach/confirm`,
  UNLINK: `${API_HOST}/api/user-wallet/link/detach`,
  GET_NFT_CLAIMED: `${API_HOST}/api/public/nft-item/migration/hashed-snail/refresh-search`,
  NFT_ITEM_MIGRATION: `${API_HOST}/api/public/nft-item/migration/hashed-snail/snapshot/v2/sign`,
};

const SWAP_API = { 
  POOL_STATUS: `${API_HOST}/api/swap/pool/status`,
  SWAP_ORDER: `${API_HOST}/api/swap/order`,
  SWAP_ORDER_ESTIMATE: `${API_HOST}/api/swap/order/estimate`,
  SWAP_ORDER_SEARCH: `${API_HOST}/api/swap/order/search`,
  CONFIG_SWAP_SEARCH: `${API_HOST}/api/configuration/swap/pool/search`,
}

export {
  API_HOST,
  USER_API,
  API_FILE,
  RESULTS_API,
  FINANCE_API,
  GAME_API,
  GAME_INTERACTION_API,
  WEB3_API,
  SWAP_API
};
