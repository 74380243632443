import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import CardModal from '../components/layouts/CardModal';

import avt from '../assets/images/slider/userAvatar.svg';
import button_capture from '../assets/images/icon/choose_photo.png';
import HomeTab from './AuthorTab/HomeTab';
import NullTab from './AuthorTab/NullTab';
import Activities from './AuthorTab/Activities';
import { USER_API } from '../components/api-url';
import { getApi, putApi, postApi } from '../utils/requestUtils';
import { PencilSquare, Power, Copy } from 'react-bootstrap-icons';
import { showError, showSuccess } from '../components/toast';
import authConfig from '../configs/auth';

import AvatarModal from '../components/AvatarModal';
import { Image } from 'react-bootstrap'

const Authors02 = () => {

  const [path, setPath] = useState('')
  const myPath = localStorage.getItem('userPath')
  const [profile, setProfile] = useState(null)
  const [reload, setReload] = useState(0)

  useEffect(() => {
    // Extracting URL parameters
    const params = new URLSearchParams(window.location.search);
    const pathParam = params.get('path');

    // Update state with the extracted parameter
    if (pathParam) {
      setPath(pathParam);
    }
  }, []);

  useEffect(() => { 
    if(path){
      getProfile()
    }
  },[path, reload])

  const getProfile = async () => { 
    try {
      const response = await getApi(USER_API.GET_USER_PROFILE + `?path=${path}`);
      if (response.data) {
        setProfile(response.data)
      }
    } catch (error) { 
      showError(error.response?.data?.message)
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(profile?.userWalletAddress)
      .then(() => {
        showSuccess('Copied to clipboard')
      })
      .catch(err => {
        console.error('Failed to copy: ', err)
      });
  };


  const [menuTab] = useState(
    [
      {
        class: 'active',
        name: 'HOME'
      },
      {
        class: '',
        name: 'ITEMS'
      },
      {
        class: '',
        name: 'NFT'
      },
      {
        class: '',
        name: 'DASHBOARD'
      },
      {
        class: '',
        name: 'ACTIVITIES'
      },
    ]
  )
  
  const [panelTab, setPanelTab] = useState(0)

  const [modalShow, setModalShow] = useState(false)

  const handleTabClick = (index) => {
    setPanelTab(index)
  }

  const renderTab = (index) => { 
    switch (index) { 
      case 0:
        return (
          <HomeTab />
        )
      case 1:
        return (
          <NullTab />
        )
      case 2:
        return (
          <NullTab />
        )
      case 3:
        return (
          <NullTab />
        )
      case 4:
        return (
          <Activities />
        )
    }
  }

  return (
    <div className='authors-2'>
      <Header />
      <section className="flat-title-page inner bg2">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">{profile?.nickname}</h1>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section className="tf-section authors pt-5">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-4 col-12">
              <div className="sc-card-profile text-left mb-4">
                <div className="bg-primary-custom p-4 b-r-12">
                  <div className='float-left w-100 mt-2' style={{height: '220px'}}>
                  <div 
                    className="avatar rounded-full bg-transparent"
                  >
                    <Image src={profile?.profileImage ? profile?.profileImage + `?t=${new Date().getTime()}` : avt} alt="" responsive className="w-100" />
                  </div>
                  {myPath === path && 
                    <div id="upload-profile ">
                      <img src={button_capture} alt="button_capture" className="button_capture" onClick={() => setModalShow(true)} />

                    
                    </div>
                  }
                  </div>
                  <div className='w-100 mt-4'>
                   <h4 className='mt-3'>{profile?.nickname}</h4>
                  </div>
                  
                  <h5 className='mt-3 color-9'>User ID: {profile?.id}</h5>
                  <p className='mt-4 color-9'>
                    {profile?.bio}
                  </p>
                  {myPath === path && 
                    <div className='w-100 mt-4 mb-3'>
                      <Link to="/edit-profile" className="align-items-center d-flex text-center btn-default btn-primary justify-content-center w-100">
                        <PencilSquare className=" mr-4" />
                        Edit profile
                      </Link>
                    </div>
                  }
                  {myPath === path && 
                    <div className='w-100 mt-4 mb-3'>
                      <button onClick={()=> {
                        window.localStorage.removeItem('userData')
                        window.localStorage.removeItem(authConfig.storageTokenKeyName)
                        window.location.href = '/'
                      }} className="align-items-center d-flex text-center sc-button bag fl-button pri-3 no-bg justify-content-center w-100">
                        <Power className=" mr-4" />
                        Logout
                      </button>
                    </div>
                  }
                  {profile?.userWalletAddress && 
                   <div className='w-100 mt-4 mb-2'>
                      <input type="text" value={profile?.userWalletAddress} readOnly className="inputcopy"/>
                      <button
                        type="button"
                        className="btn-copycode-profile mt-2 mr-3"
                        onClick={copyToClipboard}
                      >
                        <Copy color="#fff" />
                      </button>
                    </div>
                  }
                </div>


              </div>
            </div>

            <div className="col-xl-9 col-lg-8 col-md-8 col-12">
              <Tabs>
                <TabList className="p-0">
                  {
                    menuTab.map((item, index) => (
                      <Tab 
                        key={index}
                        onClick={() => handleTabClick(index)}
                      >
                        {item.name}
                      </Tab>
                    ))
                  }
                </TabList>

                <div className="content-tab mt-4">
                      {renderTab(panelTab)}
                </div>
              </Tabs>
            </div>

          </div>
          
        </div>
      </section>
      <AvatarModal
        image={profile?.profileImage}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSuccess={() => {
          setModalShow(false);
          setReload(reload + 1)
        }}
      />
      <Footer />
    </div>
  );
}

export default Authors02;
