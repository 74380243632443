
import { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import { useSearchParams, Link } from "react-router-dom";
import { getApi, postApi } from '../utils/requestUtils';
import { GAME_API } from '../components/api-url';
import Footer from '../components/footer/Footer';
import TrendingNft from '../components/layouts/home/TrendingNft';
import Loading from '../components/loading';
import LinkAddress from '../components/LinkAddress';
import ColectionEmpty from '../assets/images/backgroup-secsion/ColectionEmpty.png'

const initNftValueFilter = { 
  "type": [],
  "dapp": [],
  "filter": [],
  "orderBy": "createdAt",
  "keyword": "",
  "keywordBy": ["name"],
  "reverse": false,
  "page": 1,
  "limit": 8
}
const CollectionDetail = () => {
  const [queryParameters] = useSearchParams()
  const [liveAuctionData, setLiveAuctionData] = useState([]);
  const [nftFilter, setNftFilter] = useState(initNftValueFilter)
  const [collectionDetail, setCollectionDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const formatNumber = (num) => { 
    if(!num) return 0;
    if (Number.isInteger(num)) {
      // Format the integer with commas
      return num.toLocaleString();
    } else {
      // Format the float with 4 decimal places
      return num.toFixed(4);
    }
  }

  const fetchAutictonData = async (vendor, code) => {
    try {
      setIsLoading(true);
      const res = await postApi(GAME_API.NFT_ITEM_SEARCH, {
        ...nftFilter,
        filter: [
          {
            id: 'vendor',
            value: vendor,
            op: 'eq'
          },
          {
            id: 'collectionCode',
            value: code,
            op: 'eq'
          }
        ]
      })
      setIsLoading(false);
      if(res.data){
        setLiveAuctionData(res.data);

      }
    } catch (error) { 
      setIsLoading(false);
    }
    

}
  
  const fetchCollectionDetail = async (vendor, code) => { 
    try {
      setIsLoading(true);
      const res = await getApi(GAME_API.NFT_COLLECTION_DETAIL, {vendor, code})
      setIsLoading(false);
      if(res.data){
        setCollectionDetail(res.data);
      }
    } catch (error) { 
      console.log(error)
      setIsLoading(false);
    }
  }

  useEffect(() => { 
    const vendor = queryParameters.get('vendor');
    const code = queryParameters.get('code');
    if(vendor && code) {
      fetchCollectionDetail(vendor, code)
    }
  }, [queryParameters]);

  useEffect(() => {
    const vendor = queryParameters.get('vendor');
    const code = queryParameters.get('code');
    if(nftFilter){
      fetchAutictonData(vendor, code)
    }
  }, [nftFilter]);
  

  return (
    <div className='home-7'>
      {isLoading && <Loading />}
      <Header />
      
      {collectionDetail && ( 
        <div className='tf-section' style={{paddingBottom: 0}}>
          
          <section className="themesflat-container mt-40">
            <Link to="/collections" className="back-btn text-life3 heading-6">
              <i className="fa fa-angle-left mr-1"></i> Collection
            </Link>
            <div className='banner-image mt-40'>
              {collectionDetail?.bannerImageUrl ? (
                <img src={collectionDetail?.bannerImageUrl} />
              ) : ( 
                <img src={ColectionEmpty}  />
              )}
            </div>

            <div className="page-info">
              <div className="container-fluid">
                <div className='row align-items-end'>
                  <div className='col-md-3 col-lg-2'>
                    <div className="page-avatar">
                      <img src={collectionDetail?.imageUrl} alt="avatar" />
                    </div>
                  
                  </div>
                  <div className='col-md-9 col-lg-10'>
                    <div className="page-title-heading mg-bt-12">
                      <h2>
                        {collectionDetail?.name}
                      </h2>
                      <h5 className='mt-3'>
                        by <span > 
                        <LinkAddress className='text-life3' address={collectionDetail?.contractDeployer} />
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div className='col-md-12'>

                  </div>
                </div>
              </div>
            </div> 

            <div className='analytics-info'>
              <div className="container-fluid">
                <div className='row' style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div className='mt-2'>
                    <div className='info-item'>
                      <h4>Volume</h4>
                      <p>{formatNumber(collectionDetail?.totalVolume)} ETH</p>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <div className='info-item'>
                      <h4>Floor price</h4>
                      <p>{formatNumber(collectionDetail?.floorPrice)} ETH</p>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <div className='info-item'>
                      <h4>Average price</h4>
                      <p>{formatNumber(collectionDetail?.averagePrice)} ETH</p>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <div className='info-item'>
                      <h4>Sale</h4>
                      <p>{formatNumber(collectionDetail?.totalSales)} ETH</p>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <div className='info-item'>
                      <h4>Num Owners</h4>
                      <p>{formatNumber(collectionDetail?.numOwners)}</p>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <div className='info-item'>
                      <h4>Market cap</h4>
                      <p>${formatNumber(collectionDetail?.marketCap)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section>
        </div>
      )}
      
      <TrendingNft 
        data={liveAuctionData}
        title=""
        valueFilter={nftFilter}
        setValueFilter={(value) => setNftFilter(value)}
      />

      <Footer />

    </div>
  );
}

export default CollectionDetail;