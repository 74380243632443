
import { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import ItemContent from '../components/layouts/home-8/ItemContent';
import SideBar from '../components/layouts/home-8/SideBar';
import { getApi, postApi } from '../utils/requestUtils';
import { GAME_API } from '../components/api-url';
const Home08 = () => {
  const [dataCate, setDataCate] = useState(
    [
      {
        title: 'Categories',
        content: [
          {
            field: 'Art',
            checked: ''
          },
          {
            field: 'Music',
            checked: ''
          },
          {
            field: 'Trading Cards',
            checked: ''
          },
          {
            field: 'Collectibles',
            checked: ''
          },
          {
            field: 'Utility',
            checked: ''
          },
        ]
      },
      {
        title: 'Item Types',
        content: [
          {
            field: 'Consumable',
            checked: ''
          },
          {
            field: 'Membership',
            checked: ''
          },
          {
            field: 'Gift',
            checked: ''
          },
        ]
      },
      {
        title: 'Dapps',
        content: []
      },
    ]
  )

  const getDapps = async () => {
    try {
      const payload = {
        "orderBy": "name",
        "reverse": false,
        "limit": 10,
        "page": 1
      }
      await postApi(GAME_API.GAME_SEARCH, payload).then((res) => {
        let newData = [...dataCate]
        newData[2].content = res.data.rows.map((item) => {
          return {
            ...item,
            field: item.name,
            checked: ''
          }
        })
        setDataCate([...newData])
      }).catch((error) => {
        console.log(error)
      })
    }
    catch (error) {
      console.log('error', error);
    }
  }

  useEffect(() => {
    getDapps()
  }, []);
  const [priceRange, setPriceRange] = useState({ min: 1, max: 2000 });
  return (
    <div className='home-8'>
      <Header />
      <section className="tf-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-box-17">
              <SideBar dataCate={dataCate} onHandCate={(value) => setDataCate(value)} priceRange={priceRange} onValueChange={(value) => setPriceRange(value)} />
            </div>

            <div className="col-box-83">
              <ItemContent dataCate={dataCate} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home08;