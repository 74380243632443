// reducers/index.js
import { combineReducers } from 'redux';
import cartReducer from './cartReducer';
import cartCount from './cartCount';

const rootReducer = combineReducers({
  cart: cartReducer,
  cartCount: cartCount,
});

export default rootReducer;