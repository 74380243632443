import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import menus from "../../pages/menu";
import logoheader from '../../assets/images/logo/logo.png'
import logoheader2x from '../../assets/images/logo/logo@2x.png'
import logodark from '../../assets/images/logo/logo_dark.png'
import logodark2x from '../../assets/images/logo/logo_dark@2x.png'
import { Dropdown } from 'react-bootstrap';

import { isLoggedIn } from '../../utils/authUtils';

import authConfig from '../../configs/auth';

import { connect } from 'react-redux';
import { FINANCE_API } from '../api-url';
import { getApi, putApi, postApi } from '../../utils/requestUtils';

import ShoppingCart from '../shoppingCart';

const Header = ({ cart, cartCount }) => {
  const { pathname } = useLocation();
  const [openCart, setOpenCart] = useState(false);
  const [points, setPoints] = useState(0)
  const headerRef = useRef(null)
  const formatNumber = (num) => {
    if (!num) return 0;
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  useEffect(() => {

    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn()) {
      getApi(FINANCE_API.GET_FINANCE).then(res => {
        if (res?.data) {
          setPoints(res.data?.point)
        }
      })
    }
  }, [])



  const isSticky = (e) => {
    const header = document.querySelector('.js-header');
    const scrollTop = window.scrollY;
    scrollTop >= 300 ? header.classList.add('is-fixed') : header.classList.remove('is-fixed');
    scrollTop >= 400 ? header.classList.add('is-small') : header.classList.remove('is-small');
  };

  const menuLeft = useRef(null)
  const btnToggle = useRef(null)
  const btnSearch = useRef(null)

  const [isOpen, setIsOpen] = useState(false);

  const toggleUser = () => {
    setIsOpen(!isOpen);
  };


  const menuToggle = () => {
    menuLeft.current.classList.toggle('active');
    btnToggle.current.classList.toggle('active');
  }

  const searchBtn = () => {
    btnSearch.current.classList.toggle('active');
  }

  const [activeIndex, setActiveIndex] = useState(null);
  const handleOnClick = index => {
    setActiveIndex(index);
  };



  return (
    <header id="header_main" className="header_1 js-header" ref={headerRef}>
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div id="site-header-inner">
              <div className="wrap-box flex">
                <div id="site-logo" className="clearfix">
                  <div id="site-logo-inner">
                    <Link to="/" rel="home" className="main-logo">
                      <img className='logo-dark' id="logo_header" src={logodark} srcSet={`${logodark2x}`} alt="nft-gaming" />
                      <img className='logo-light' id="logo_header" src={logoheader} srcSet={`${logoheader2x}`} alt="nft-gaming" />
                    </Link>
                  </div>
                </div>
                <div className="mobile-button" ref={btnToggle} onClick={menuToggle}><span></span></div>
                <nav id="main-nav" className="main-nav" ref={menuLeft} >
                  <ul id="menu-primary-menu" className="menu">
                    {
                      menus.map((data, index) => (
                        <li key={index} onClick={() => handleOnClick(index)} className={`${data.paths && data.paths.includes(pathname) ? 'color-primary' : ''} menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === index ? 'active' : ''} `}   >
                          <Link to={data.links}>{data.name}</Link>
                          {
                            data.namesub &&
                            <ul className="sub-menu" >
                              {
                                data.namesub.map((submenu) => (
                                  <li key={submenu.id} className={
                                    pathname === submenu.links
                                      ? "menu-item current-item"
                                      : "menu-item"
                                  }><Link to={submenu.links}>{submenu.sub}</Link></li>
                                ))
                              }
                            </ul>
                          }

                        </li>
                      ))
                    }
                  </ul>
                </nav>
                <div className="flat-search-btn flex">
                  {/* <div className="header-search flat-show-search" id="s1">
                    <Link to="#" className="show-search header-search-trigger" onClick={searchBtn}>
                      <i className="far fa-search"></i>
                    </Link>
                    <div className="top-search" ref={btnSearch}>
                      <form action="#" method="get" role="search" className="search-form">
                        <input type="search" id="s" className="search-field" placeholder="Search..." name="s" title="Search for" required="" />
                        <button className="search search-submit" type="submit" title="Search">
                          <i className="icon-fl-search-filled"></i>
                        </button>
                      </form>
                    </div>
                  </div> */}
                  <div className="sc-btn-top mg-r-12" id="site-header">
                    
                    {isLoggedIn() ? (
                      <div className="d-flex flex-row">
                        <div className="icon-button ml-2">
                          <div className="language-flag">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" fontSize="1.625rem" className="iconify iconify--emojione" width="1em" height="1em" viewBox="0 0 64 64">
                              <path fill="#ed4c5c" d="M48 6.6C43.3 3.7 37.9 2 32 2v4.6z"></path>
                              <path fill="#fff" d="M32 11.2h21.6C51.9 9.5 50 7.9 48 6.6H32z"></path>
                              <path fill="#ed4c5c" d="M32 15.8h25.3c-1.1-1.7-2.3-3.2-3.6-4.6H32z"></path>
                              <path fill="#fff" d="M32 20.4h27.7c-.7-1.6-1.5-3.2-2.4-4.6H32z"></path>
                              <path fill="#ed4c5c" d="M32 25h29.2c-.4-1.6-.9-3.1-1.5-4.6H32z"></path>
                              <path fill="#fff" d="M32 29.7h29.9c-.1-1.6-.4-3.1-.7-4.6H32z"></path>
                              <path fill="#ed4c5c" d="M61.9 29.7H32V32H2c0 .8 0 1.5.1 2.3h59.8c.1-.8.1-1.5.1-2.3s0-1.6-.1-2.3"></path>
                              <path fill="#fff" d="M2.8 38.9h58.4c.4-1.5.6-3 .7-4.6H2.1c.1 1.5.3 3.1.7 4.6"></path>
                              <path fill="#ed4c5c" d="M4.3 43.5h55.4c.6-1.5 1.1-3 1.5-4.6H2.8c.4 1.6.9 3.1 1.5 4.6"></path>
                              <path fill="#fff" d="M6.7 48.1h50.6c.9-1.5 1.7-3 2.4-4.6H4.3c.7 1.6 1.5 3.1 2.4 4.6"></path>
                              <path fill="#ed4c5c" d="M10.3 52.7h43.4c1.3-1.4 2.6-3 3.6-4.6H6.7c1 1.7 2.3 3.2 3.6 4.6"></path>
                              <path fill="#fff" d="M15.9 57.3h32.2c2.1-1.3 3.9-2.9 5.6-4.6H10.3c1.7 1.8 3.6 3.3 5.6 4.6"></path>
                              <path fill="#ed4c5c" d="M32 62c5.9 0 11.4-1.7 16.1-4.7H15.9c4.7 3 10.2 4.7 16.1 4.7"></path>
                              <path fill="#428bc1" d="M16 6.6c-2.1 1.3-4 2.9-5.7 4.6c-1.4 1.4-2.6 3-3.6 4.6c-.9 1.5-1.8 3-2.4 4.6c-.6 1.5-1.1 3-1.5 4.6c-.4 1.5-.6 3-.7 4.6c-.1.8-.1 1.6-.1 2.4h30V2c-5.9 0-11.3 1.7-16 4.6"></path>
                              <path fill="#fff" d="m25 3l.5 1.5H27l-1.2 1l.4 1.5l-1.2-.9l-1.2.9l.4-1.5l-1.2-1h1.5zm4 6l.5 1.5H31l-1.2 1l.4 1.5l-1.2-.9l-1.2.9l.4-1.5l-1.2-1h1.5zm-8 0l.5 1.5H23l-1.2 1l.4 1.5l-1.2-.9l-1.2.9l.4-1.5l-1.2-1h1.5zm4 6l.5 1.5H27l-1.2 1l.4 1.5l-1.2-.9l-1.2.9l.4-1.5l-1.2-1h1.5zm-8 0l.5 1.5H19l-1.2 1l.4 1.5l-1.2-.9l-1.2.9l.4-1.5l-1.2-1h1.5zm-8 0l.5 1.5H11l-1.2 1l.4 1.5l-1.2-.9l-1.2.9l.4-1.5l-1.2-1h1.5zm20 6l.5 1.5H31l-1.2 1l.4 1.5l-1.2-.9l-1.2.9l.4-1.5l-1.2-1h1.5zm-8 0l.5 1.5H23l-1.2 1l.4 1.5l-1.2-.9l-1.2.9l.4-1.5l-1.2-1h1.5zm-8 0l.5 1.5H15l-1.2 1l.4 1.5l-1.2-.9l-1.2.9l.4-1.5l-1.2-1h1.5zm12 6l.5 1.5H27l-1.2 1l.4 1.5l-1.2-.9l-1.2.9l.4-1.5l-1.2-1h1.5zm-8 0l.5 1.5H19l-1.2 1l.4 1.5l-1.2-.9l-1.2.9l.4-1.5l-1.2-1h1.5zm-8 0l.5 1.5H11l-1.2 1l.4 1.5l-1.2-.9l-1.2.9l.4-1.5l-1.2-1h1.5zm2.8-14l1.2-.9l1.2.9l-.5-1.5l1.2-1h-1.5L13 9l-.5 1.5h-1.4l1.2.9zm-8 12l1.2-.9l1.2.9l-.5-1.5l1.2-1H5.5L5 21l-.5 1.5h-1c0 .1-.1.2-.1.3l.8.6z"></path>
                            </svg>  
                          </div>
                        </div>
                        <div className='icon-button ml-2'>
                          <i className="far fa-bell"></i>
                        </div>
                        

                        {/* <div className='icon-button ml-2 mr-2' onClick={() => setOpenCart(true)}>
                          <i className="far fa-shopping-cart"></i>
                          {cartCount > 0 && (
                            <span className="badge-header position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{cartCount}</span>
                          )}
                        </div> */}

                        <div className="dropdown-default ml-2 ">
                          <Dropdown>
                            <Dropdown.Toggle>
                              <div className='icon-button'>
                                <i className="far fa-user-circle"></i>
                              </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ margin: 0 }}>
                              <div className="balance-header">
                                <i className="fas fa-dollar-sign mr-3"></i> {formatNumber(points)} DSP
                              </div>
                              <Dropdown.Item href={`/author/?path=${localStorage?.userPath}`}>
                                <i className="fas fa-user-check mr-3"></i>
                                View Profile
                              </Dropdown.Item>
                              <Dropdown.Item href="/edit-profile">
                                <i className="fas fa-user-cog mr-3"></i>
                                Account settings
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  window.localStorage.removeItem('userData')
                                  window.localStorage.removeItem(authConfig.storageTokenKeyName)
                                  window.location.href = '/'
                                }}
                              >
                                <i className="fa fa-sign-out mr-3"></i>
                                Logout
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                      </div>
                    ) : (
                      <div className="d-flex flex-row align-items-center">
                        <Link to="/login" className="sc-button header-slider style style-1 fl-button pri-1">
                          <span style={{ paddingRight: 20 }}>
                            <i className="icon-fl-users-filled"></i> Sign in
                          </span>
                        </Link>
                        {/* <div className='icon-button ml-2' onClick={() => setOpenCart(true)}>
                          <i className="far fa-shopping-cart"></i>
                          {cartCount > 0 && (
                            <span className="badge-header position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{cartCount}</span>
                          )}
                        </div> */}
                      </div>
                    )}

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openCart && <ShoppingCart cart={cart?.items} onClose={() => setOpenCart(false)} />}
    </header>
  );
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    cartCount: state.cartCount,
  };
};

export default connect(mapStateToProps)(Header);
