

import Login from "./Login";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";



const routes = [
  { path: '/login', component: <Login />},
  { path: '/sign-up', component: <SignUp />},
  { path: '/forgot-password', component: <ForgotPassword />},
]

export default routes;