import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types'
import { Link, useSearchParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Dropdown } from 'react-bootstrap';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Countdown from "react-countdown";
import CardModal from './CardModal'
import dstLogo from '../../assets/images/icons/dst.svg'
import NftEmpty from '../../assets/images/backgroup-secsion/NftEmpty.png'
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import { useDispatch } from 'react-redux';
import { addToCart } from '../../actions/cartActions';
import LinkAddress from '../LinkAddress';

const LiveAuction = props => {
  const data = props.data;
  const title = props?.title;
  const dispatch = useDispatch();
  const [queryParameters] = useSearchParams()
  const vendor = queryParameters.get('vendor');
  const collectionCode = queryParameters.get('collectionCode');
  const handleAddToCart = (item) => {
    dispatch(addToCart(item));
  };

  const [modalShow, setModalShow] = useState(false);

  return (
    <Fragment>
      <section className="tf-section live-auctions">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-live-auctions">
                <h2 className="tf-title pb-20">
                  {title ? title : 'Trending Items'}
                </h2>
                <Link to={`/collection/detail?vendor=${vendor}&code=${collectionCode}`} className="exp style2">EXPLORE MORE</Link>
              </div>
            </div>
            <div className="col-md-12">
              <Swiper
                modules={[Navigation, Pagination, A11y]}
                slidesPerView={1}
                spaceBetween={10}
                breakpoints={{
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20
                  },
                  991: {
                    slidesPerView: 3,
                  },
                  1300: {
                    slidesPerView: 4,
                  },
                }}
                navigation
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
              >
                {
                 data.length > 0 && data.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className="swiper-container show-shadow carousel auctions">
                        <div className="swiper-wrapper">
                          <div className="swiper-slide">
                            <div className="slider-item">
                              <div key={index} className={`sc-card-product menu_card style-h7 ${item.feature ? 'comingsoon' : ''} `}>
                                <div className="meta-info style">
                                  <div className="author">
                                    <div className="avatar">
                                      <img src={item.collectionImage} alt={item.name} />
                                    </div>
                                    <div className="info">
                                      <Link to={`/collection/detail?vendor=${item?.vendor}&code=${item?.collectionCode}`} >
                                        <h6>  {item.collectionName} </h6>
                                      </Link>

                                      <span>
                                      
                                        <LinkAddress address={item?.contractDeployer} />  
                                      </span>
                                    </div>
                                  </div>

                                </div>
                                <div className="card-media">
                                  <Link to={`/nft-details?vendor=${item?.vendor}&collectionCode=${item?.collectionCode}&code=${item?.code}`} >
                                      {item.assetType !== 'IMAGE' ? ( 
                                        <embed src={item?.thumbnailUrl ? item?.thumbnailUrl : item?.assetUri} alt={item?.name} />
                                      ) : ( 
                                        <img src={item?.thumbnailUrl ? item?.thumbnailUrl : NftEmpty} alt={item?.name} />
                                      )}
                                  </Link>
                                </div>
                                <div className="card-title text-center">
                                  <h5>
                                    <Link to={`/nft-details?vendor=${item?.vendor}&collectionCode=${item?.collectionCode}&code=${item?.code}`} >
                                      {item.name ? item.name : '-'}
                                    </Link></h5>
                                </div>
                                <div className="meta-info">
                                  <div className="author">
                                    <div className="info style2">
                                      <span>Price</span>
                                    </div>
                                  </div>
                                  <div className="price-item">
                                    {item?.price ? (
                                      <span>
                                        <img src={dstLogo} alt={item?.name} /> {item?.price} {item?.currency}
                                      </span>
                                    ) : (
                                      <span>
                                        -
                                      </span>
                                    )}
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <CardModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Fragment>

  );
}

LiveAuction.propTypes = {
  data: PropTypes.array.isRequired,
}


export default LiveAuction;
